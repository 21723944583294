import {NgModule} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import { AddProjectComponent } from './add-project/add-project.component';
import { ManageProjectsRoutingModule } from './manage-projects-routing.module';
import { ManageProjectsComponent } from './manage-projects.component';


@NgModule({
    declarations: [
        ManageProjectsComponent,
        AddProjectComponent
    ],
    imports: [
        ManageProjectsRoutingModule,
        SharedModule
    ]
})
export class ManageProjectsModule {
}
