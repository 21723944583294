import {GenericService} from './generic.service';
import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import { StorageService } from './storage.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService extends GenericService {

  constructor(private http: HttpClient,private storageService: StorageService) {
    super();
  }


  login(credentials: any): Observable<any> {
    const url = environment.baseUrlApi + '/auth/login/admin';
    return <Observable<any>>this.http.post(url, credentials, {headers: this.headers});
  }

  sendForgetPasswordEmail(email: string): Observable<any> {
    const url = environment.baseUrlApi + '/auth/forgetPassword';
    return <Observable<any>>this.http.post(url, {email}, {headers: this.headers});
  }

  isLoggedIn() {
    return this.storageService.read(StorageService.TOKEN_STORAGE) != null;
  }

  refreshToken() {
    return this.http.get(environment.baseUrlApi + "/auth/refreshtoken");
  }

   logout() {
    return this.http.get(environment.baseUrlApi + "/auth/logout");
  }

}
