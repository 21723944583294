// add-client.component.ts
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Admin } from 'src/app/shared/models/admin/admin';
import { AdminService } from 'src/app/shared/services/admin.service';
import { ClientService } from 'src/app/shared/services/client.service';
import { Utils } from 'src/app/shared/utils/utils';
import { Subscription } from 'rxjs';

import { environment } from 'src/environments/environment';
import { ChangeDetectorRef } from '@angular/core';
import { Client } from 'src/app/shared/models/Client';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.css']
})
export class AddClientComponent implements OnInit {
  clientForm: FormGroup;
  admins: Admin[] = [];
  busy: Subscription;
  pathsBanner: string[] = [];
  pathsCNI: string[] = [];
  pathsUrssaf: string[] = [];
  pathsImpots: string[] = [];
  pathsRetraite: string[] = [];
  pathsPrevoyance: string[] = [];
  pathsMutuelle: string[] = [];
  pathsCp: string[] = [];
  pathsCibtp: string[] = [];
  pathsNet: string[] = [];
  pathsBilan: string[] = [];
  sectors = ['BTP', 'Restauration', 'Associations', 'LMNP', 'SCI', 'Conseils', 'Sécurité', 'Transport', 'Nettoyage'];
  client: Client = new Client();
  submitted = false;
  checked: boolean;
  isEditMode = false;
  showPassword = false;
  baseUrl: string = environment.filesUrl;
  ready: boolean;
  showUrssafPassword: boolean = false;
  showImpotsPassword: boolean = false;
  showRetraitePassword: boolean = false;
  showPrevoyancePassword: boolean = false;
  showMutuellePassword: boolean = false;
  showCongesPassword: boolean = false;
  showCIBTPPassword: boolean = false;
  showNETPassword: boolean = false;
  selected_accesses_ids = [];

  constructor(
    private fb: FormBuilder,
    private clientService: ClientService,
    private router: Router,
    private adminService: AdminService,
    private route: ActivatedRoute,private cd: ChangeDetectorRef,private translate: TranslateService
  ) {
    this.isEditMode = router.url.indexOf('modify-client') > 0;
    this.client.id = +this.route.snapshot.paramMap.get('id')
  }

  ngOnInit() {
    this.deleteAllCookies();
    if (this.isEditMode) {
      this.setClient();
    }
    this.loadAdmins();

  }

  toggleUrssafPasswordVisibility() {
    this.showUrssafPassword = !this.showUrssafPassword;
  }
  
  toggleImpotsPasswordVisibility() {
    this.showImpotsPassword = !this.showImpotsPassword;
  }
  
  toggleRetraitePasswordVisibility() {
    this.showRetraitePassword = !this.showRetraitePassword;
  }
  
  togglePrevoyancePasswordVisibility() {
    this.showPrevoyancePassword = !this.showPrevoyancePassword;
  }
  
  toggleMutuellePasswordVisibility() {
    this.showMutuellePassword = !this.showMutuellePassword;
  }

  toggleCongesPasswordVisibility() {
    this.showCongesPassword = !this.showCongesPassword;
  }

  toggleCIBTPPasswordVisibility() {
    this.showCIBTPPassword = !this.showCIBTPPassword;
  }

  toggleNETPasswordVisibility() {
    this.showNETPassword = !this.showNETPassword;
  }
  

  isEmptyClient() {
    return !(this.client.company_name && this.client.director_name && this.client.email && this.client.phone);
  }

  setClient() {
    const baseContext = this;
    this.clientService.getClientById(this.client.id)
      .subscribe((data: any) => {
      /*  if (data.upload_document) {
          console.log(data);
          this.pathsBanner.push(this.baseUrl + data.upload_document);
          this.cd.detectChanges();
        }*/
        
        
        this.client = data;
        
        this.ready = true;
        console.log(this.client);
        this.client.imgs.forEach((doc) => {
          this.pathsBanner.push(environment.filesUrl + doc.path);
        });
        this.client.imgs_cni.forEach((doc) => {
          this.pathsCNI.push(environment.filesUrl + doc.path);
        });
        this.client.imgs_urssaf.forEach((doc) => {
          this.pathsUrssaf.push(environment.filesUrl + doc.path);
        });
        this.client.imgs_impots.forEach((doc) => {
          this.pathsImpots.push(environment.filesUrl + doc.path);
        });
        this.client.imgs_retraite.forEach((doc) => {
          this.pathsRetraite.push(environment.filesUrl + doc.path);
        });
        this.client.imgs_prevoyance.forEach((doc) => {
          this.pathsPrevoyance.push(environment.filesUrl + doc.path);
        });
        this.client.imgs_mutuelle.forEach((doc) => {
          this.pathsMutuelle.push(environment.filesUrl + doc.path);
        });
        this.client.imgs_conges_payes.forEach((doc) => {
          this.pathsCp.push(environment.filesUrl + doc.path);
        });
        this.client.imgs_cibtp.forEach((doc) => {
          this.pathsCibtp.push(environment.filesUrl + doc.path);
        });
        this.client.imgs_net.forEach((doc) => {
          this.pathsNet.push(environment.filesUrl + doc.path);
        });
        this.client.imgs_bilan.forEach((doc) => {
          this.pathsBilan.push(environment.filesUrl + doc.path);
        });
        if(this.client)
        {
          this.client.urssaf_identifiant = this.client.urssaf.identifiant;
          this.client.urssaf_password = this.client.urssaf.password;
          this.client.impots_identifiant = this.client.impots.identifiant;
          this.client.impots_password = this.client.impots.password;
          this.client.retraite_identifiant = this.client.retraite.identifiant;
          this.client.retraite_password = this.client.retraite.password;
          this.client.prevoyance_identifiant = this.client.prevoyance.identifiant;
          this.client.prevoyance_password = this.client.prevoyance.password;
          this.client.mutuelle_identifiant = this.client.mutuelle.identifiant;
          this.client.mutuelle_password = this.client.mutuelle.password;
          this.client.cp_login = this.client.cp_login;
          this.client.cp_password = this.client.cp_password;
          this.client.cibtp_login = this.client.cibtp_login;
          this.client.cibtp_password = this.client.cibtp_password;
          this.client.net_siret = this.client.net_siret;
          this.client.net_nom = this.client.net_nom ;
          this.client.net_prenom = this.client.net_prenom;
          this.client.net_password = this.client.net_password;
          this.client.manager_ids = data.admins.map(a => a.admin_id);
          this.client.remarques = this.client.remarques;
        }
        
        if (this.client.creation_date) {
          this.checked = true;
        }
      }, (error) => {
        console.error(error);
      });
  }

  submitClient() {
    this.submitted = true;
    if (this.isEmptyClient()) {
      Utils.sweetAlert('error', 'Erreur !!', 'Remplir les champs');
      return;
    }

    if (this.isEditMode) {
      console.log(this.client);
      this.busy = this.clientService.updateClient(this.client.id, this.client)
        .subscribe(
          (data) => {
            Utils.sweetAlert('success', 'Succès', 'Modification client avec succès');
            this.router.navigate(['/admins/list-clients']);
          },
          (error) => {
            if (error.status === 422) { // Validation error
              this.handleValidationErrors(error.error);
            } else {
              Utils.sweetAlert('error', 'Erreur', 'Une erreur est survenue');
            }
          }
        );
    } else {
      console.log(this.client);
      this.busy = this.clientService.addClient(this.client)
        .subscribe(
          (data) => {
            Utils.sweetAlert('success', 'Succès', 'Ajout client avec succès');
            this.router.navigate(['/admins/list-clients']);
          },
          (error) => {
            if (error.status === 422) { // Validation error
              this.handleValidationErrors(error.error);
            } else {
              Utils.sweetAlert('error', 'Erreur', 'Une erreur est survenue');
            }
          }
        );
    }
  }

  handleValidationErrors(errors) {
    if (errors.email) {
        Utils.sweetAlert('error', 'Erreur', 'Email est invalide ou déjà utilisé');
    }
    if (errors.director_name) {
        Utils.sweetAlert('error', 'Erreur', 'Nom du dirigeant est requis');
    }
    if (errors.company_name) {
        Utils.sweetAlert('error', 'Erreur', 'Nom de l’entreprise est requis');
    }
    if (errors.phone) {
        Utils.sweetAlert('error', 'Erreur', 'Téléphone est requis');
    }
    if (errors.address) {
        Utils.sweetAlert('error', 'Erreur', 'Adresse est requise');
    }
    if (errors.activity_sector) {
        Utils.sweetAlert('error', 'Erreur', 'Secteur d\'activité est requis');
    }
    if (errors.siret) {
        Utils.sweetAlert('error', 'Erreur', 'Siret est requis');
    }
    if (errors.idcc) {
        Utils.sweetAlert('error', 'Erreur', 'IDCC est requis');
    }
    if (errors.code_naf) {
        Utils.sweetAlert('error', 'Erreur', 'Code NAF est requis');
    }
    if (errors.creation_date) {
        Utils.sweetAlert('error', 'Erreur', 'Date de création est invalide');
    }
    if (errors.urssaf) {
        Utils.sweetAlert('error', 'Erreur', 'Informations URSSAF sont requises');
    }
    if (errors.impots) {
        Utils.sweetAlert('error', 'Erreur', 'Informations IMPOTS sont requises');
    }
    if (errors.retraite) {
        Utils.sweetAlert('error', 'Erreur', 'Informations RETRAITE sont requises');
    }
    if (errors.prevoyance) {
        Utils.sweetAlert('error', 'Erreur', 'Informations PRÉVOYANCE sont requises');
    }
    if (errors.mutuelle) {
        Utils.sweetAlert('error', 'Erreur', 'Informations MUTUELLE sont requises');
    }
    if (errors.conge) {
      Utils.sweetAlert('error', 'Erreur', 'Informations Congés Payés sont requises');
    }
    if (errors.cibtp) {
      Utils.sweetAlert('error', 'Erreur', 'Informations CIBTP sont requises');
    }
    if (errors.manager_ids) {
        Utils.sweetAlert('error', 'Erreur', 'Salarié qui gère le client est invalide');
    }
    if (errors.upload_document) {
        Utils.sweetAlert('error', 'Erreur', 'Document de téléversement est requis');
    }
    if (errors.imgs) {
        Utils.sweetAlert('error', 'Erreur', 'Images sont invalides ou requises');
    }
    if (errors.imgs && errors.imgs.length > 0) {
        errors.imgs.forEach(imgError => {
            if (imgError.path) {
                Utils.sweetAlert('error', 'Erreur', 'Chemin de l\'image est requis');
            }
            if (imgError.file_name) {
                Utils.sweetAlert('error', 'Erreur', 'Nom de l\'image est requis');
            }
            if (imgError.resource_id) {
                Utils.sweetAlert('error', 'Erreur', 'ID de la ressource de l\'image est requis');
            }
        });
    }
}

  onUploadImageCni(e) {
    const index = this.client.imgs_cni.findIndex((img) => {
      return img.file_name == e.fileName;
    });
    if (index > -1) {
      this.client.imgs_cni[index].resource_id = e.resource.resource_id;
    } else {
      this.client.imgs_cni.push({ path: e.resource.path, resource_id: e.resource.resource_id, file_name: e.fileName });
    }
    this.pathsCNI.push(environment.filesUrl + e.resource.path);
  }

  onDeleteImageCni(e) {

    const index = this.client.imgs_cni.findIndex((img) => {
      img.resource_id == e.responseJSON.resource_id;
      return img.resource_id
    });
    if (index > -1) {
      this.client.imgs_cni.splice(index, 1);
      this.pathsCNI.splice(index, 1);
    }
  }

  onUploadImageUrssaf(e) {
    const index = this.client.imgs_urssaf.findIndex((img) => {
      return img.file_name == e.fileName;
    });
    if (index > -1) {
      this.client.imgs_urssaf[index].resource_id = e.resource.resource_id;
    } else {
      this.client.imgs_urssaf.push({ path: e.resource.path, resource_id: e.resource.resource_id, file_name: e.fileName });
    }
    this.pathsUrssaf.push(environment.filesUrl + e.resource.path);
  }

  onDeleteImageUrssaf(e) {

    const index = this.client.imgs_urssaf.findIndex((img) => {
      img.resource_id == e.responseJSON.resource_id;
      return img.resource_id
    });
    if (index > -1) {
      this.client.imgs_urssaf.splice(index, 1);
      this.pathsUrssaf.splice(index, 1);
    }
  }

  onUploadImageBilan(e) {
    const index = this.client.imgs_bilan.findIndex((img) => {
      return img.file_name == e.fileName;
    });
    if (index > -1) {
      this.client.imgs_bilan[index].resource_id = e.resource.resource_id;
    } else {
      this.client.imgs_bilan.push({ path: e.resource.path, resource_id: e.resource.resource_id, file_name: e.fileName });
    }
    this.pathsBilan.push(environment.filesUrl + e.resource.path);
  }

  onDeleteImageBilan(e) {

    const index = this.client.imgs_bilan.findIndex((img) => {
      img.resource_id == e.responseJSON.resource_id;
      return img.resource_id
    });
    if (index > -1) {
      this.client.imgs_bilan.splice(index, 1);
      this.pathsBilan.splice(index, 1);
    }
  }

  onUploadImageImpots(e) {
    const index = this.client.imgs_impots.findIndex((img) => {
      return img.file_name == e.fileName;
    });
    if (index > -1) {
      this.client.imgs_impots[index].resource_id = e.resource.resource_id;
    } else {
      this.client.imgs_impots.push({ path: e.resource.path, resource_id: e.resource.resource_id, file_name: e.fileName });
    }
    this.pathsImpots.push(environment.filesUrl + e.resource.path);
  }

  onDeleteImageImpots(e) {

    const index = this.client.imgs_impots.findIndex((img) => {
      img.resource_id == e.responseJSON.resource_id;
      return img.resource_id
    });
    if (index > -1) {
      this.client.imgs_impots.splice(index, 1);
      this.pathsImpots.splice(index, 1);
    }
  }

  onUploadImageRetraite(e) {
    const index = this.client.imgs_retraite.findIndex((img) => {
      return img.file_name == e.fileName;
    });
    if (index > -1) {
      this.client.imgs_retraite[index].resource_id = e.resource.resource_id;
    } else {
      this.client.imgs_retraite.push({ path: e.resource.path, resource_id: e.resource.resource_id, file_name: e.fileName });
    }
    this.pathsRetraite.push(environment.filesUrl + e.resource.path);
  }

  onDeleteImageRetraite(e) {

    const index = this.client.imgs_retraite.findIndex((img) => {
      img.resource_id == e.responseJSON.resource_id;
      return img.resource_id
    });
    if (index > -1) {
      this.client.imgs_retraite.splice(index, 1);
      this.pathsRetraite.splice(index, 1);
    }
  }

  onUploadImagePrevoyance(e) {
    const index = this.client.imgs_prevoyance.findIndex((img) => {
      return img.file_name == e.fileName;
    });
    if (index > -1) {
      this.client.imgs_prevoyance[index].resource_id = e.resource.resource_id;
    } else {
      this.client.imgs_prevoyance.push({ path: e.resource.path, resource_id: e.resource.resource_id, file_name: e.fileName });
    }
    this.pathsPrevoyance.push(environment.filesUrl + e.resource.path);
  }

  onDeleteImagePrevoyance(e) {

    const index = this.client.imgs_prevoyance.findIndex((img) => {
      img.resource_id == e.responseJSON.resource_id;
      return img.resource_id
    });
    if (index > -1) {
      this.client.imgs_prevoyance.splice(index, 1);
      this.pathsPrevoyance.splice(index, 1);
    }
  }

  onUploadImageMutuelle(e) {
    const index = this.client.imgs_mutuelle.findIndex((img) => {
      return img.file_name == e.fileName;
    });
    if (index > -1) {
      this.client.imgs_mutuelle[index].resource_id = e.resource.resource_id;
    } else {
      this.client.imgs_mutuelle.push({ path: e.resource.path, resource_id: e.resource.resource_id, file_name: e.fileName });
    }
    this.pathsMutuelle.push(environment.filesUrl + e.resource.path);
  }

  onDeleteImageMutuelle(e) {

    const index = this.client.imgs_mutuelle.findIndex((img) => {
      img.resource_id == e.responseJSON.resource_id;
      return img.resource_id
    });
    if (index > -1) {
      this.client.imgs_mutuelle.splice(index, 1);
      this.pathsMutuelle.splice(index, 1);
    }
  }

  onUploadImageCp(e) {
    const index = this.client.imgs_conges_payes.findIndex((img) => {
      return img.file_name == e.fileName;
    });
    if (index > -1) {
      this.client.imgs_conges_payes[index].resource_id = e.resource.resource_id;
    } else {
      this.client.imgs_conges_payes.push({ path: e.resource.path, resource_id: e.resource.resource_id, file_name: e.fileName });
    }
    this.pathsCp.push(environment.filesUrl + e.resource.path);
  }

  onDeleteImageCp(e) {

    const index = this.client.imgs_conges_payes.findIndex((img) => {
      img.resource_id == e.responseJSON.resource_id;
      return img.resource_id
    });
    if (index > -1) {
      this.client.imgs_conges_payes.splice(index, 1);
      this.pathsCp.splice(index, 1);
    }
  }

  onUploadImageCibtp(e) {
    const index = this.client.imgs_cibtp.findIndex((img) => {
      return img.file_name == e.fileName;
    });
    if (index > -1) {
      this.client.imgs_cibtp[index].resource_id = e.resource.resource_id;
    } else {
      this.client.imgs_cibtp.push({ path: e.resource.path, resource_id: e.resource.resource_id, file_name: e.fileName });
    }
    this.pathsCibtp.push(environment.filesUrl + e.resource.path);
  }

  onDeleteImageCibtp(e) {

    const index = this.client.imgs_cibtp.findIndex((img) => {
      img.resource_id == e.responseJSON.resource_id;
      return img.resource_id
    });
    if (index > -1) {
      this.client.imgs_cibtp.splice(index, 1);
      this.pathsCibtp.splice(index, 1);
    }
  }

  onUploadImageNet(e) {
    const index = this.client.imgs_net.findIndex((img) => {
      return img.file_name == e.fileName;
    });
    if (index > -1) {
      this.client.imgs_net[index].resource_id = e.resource.resource_id;
    } else {
      this.client.imgs_net.push({ path: e.resource.path, resource_id: e.resource.resource_id, file_name: e.fileName });
    }
    this.pathsNet.push(environment.filesUrl + e.resource.path);
  }

  onDeleteImageNet(e) {

    const index = this.client.imgs_net.findIndex((img) => {
      img.resource_id == e.responseJSON.resource_id;
      return img.resource_id
    });
    if (index > -1) {
      this.client.imgs_net.splice(index, 1);
      this.pathsNet.splice(index, 1);
    }
  }

  onUploadImage(e) {
    const index = this.client.imgs.findIndex((img) => {
      return img.file_name == e.fileName;
    });
    if (index > -1) {
      this.client.imgs[index].resource_id = e.resource.resource_id;
    } else {
      this.client.imgs.push({ path: e.resource.path, resource_id: e.resource.resource_id, file_name: e.fileName });
    }
    this.pathsBanner.push(environment.filesUrl + e.resource.path);
  }

 

  onDeleteImage(e) {
    
    const index = this.client.imgs.findIndex((img) => {
      img.resource_id == e.responseJSON.resource_id;
      return img.resource_id
    });
    if (index > -1) {
      this.client.imgs.splice(index, 1);
      this.pathsBanner.splice(index, 1);
    }
  }
  
  loadAdmins() {
    this.adminService.getListClients().subscribe((admins: any[]) => {
      this.admins = admins.filter(admin => admin.privilege_id === 1);
    });
  }

  deleteAllCookies() {
    const cookies = document.cookie.split(';');
    cookies.forEach(cookie => {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
    });
    console.log('All cookies have been deleted');
  }

  formatPhone(value: string) {
    // Remove all non-numeric characters
    this.client.phone = value.replace(/\D/g, '');
  }
}
