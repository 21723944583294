import { Country } from "./Country";
export class User {
  user_id: number;
  first_name: string;
  last_name: string;
  mobile: number;
  email: string;
  passwordDecrypt: string ;
  congress_id: number;
  password: string;
  grade_id: number;
  country_id: string;
  country: Country = new Country();
  qr_code: string;
}
