export class Client {
    id: number;
    company_name: string;
    director_name: string;
    manager_ids: number[]; // Assuming this is a list of admin IDs managing the client
    phone: string;
    email: string;
    address: string;
    activity_sector: string;
    siret: string;
    idcc?: string; // Optional field
    code_naf: string;
    creation_date: string;
    urssaf_identifiant: string;
    urssaf_password?: string; // Optional field
    impots_identifiant: string;
    impots_password?: string; // Optional field
    retraite_identifiant: string;
    retraite_password?: string; // Optional field
    prevoyance_identifiant: string;
    prevoyance_password?: string; // Optional field
    mutuelle_identifiant: string;
    mutuelle_password?: string; // Optional field
    cp_login?: string;
    cp_password?: string;
    cibtp_login: string;
    cibtp_password: string;
    upload_document?: string; // Assuming the document will be uploaded as a file
    remarques?: string;
    imgs: any[] = [];
    imgs_urssaf: any[] = [];
    imgs_impots: any[] = [];
    imgs_retraite: any[] = [];
    imgs_prevoyance: any[] = [];
    imgs_mutuelle: any[] = [];
    imgs_conges_payes: any[] = [];
    imgs_cibtp: any[] = [];
    imgs_net: any[] = [];
    imgs_cni: any[] = [];
    imgs_bilan: any[] = [];
    admins: any[] = [];
    net_siret: string;
    net_nom: string;
    net_prenom: string;
    net_password: string;
    urssaf: {
        identifiant: string;
        password?: string; // Optional field
    };
    impots: {
        identifiant: string;
        password?: string; // Optional field
    };
    retraite: {
        identifiant: string;
        password?: string; // Optional field
    };
    prevoyance: {
        identifiant: string;
        password?: string; // Optional field
    };
    mutuelle: {
        identifiant: string;
        password?: string; // Optional field
    };
}

