// client.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  private baseUrl = environment.baseUrlApi;

  constructor(private http: HttpClient) { }

  // Add a new client
  addClient(clientData: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/add-clients`, clientData);
  }

  // Get a list of clients with pagination and search
  getListClients(perPage: number, page: number, search: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/clients`, {
      params: {
        perPage: perPage.toString(),
        page: page.toString(),
        search: search
      }
    });
  }

  // Get client details by ID
  getClientById(id: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/clients/${id}`);
  }

  // Update client details
  updateClient(id: number, clientData: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/clients/${id}`, clientData);
  }

  // Remove a client by ID
  removeClientById(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/clients/${id}`);
  }

  getAllClientEmails() {
    const url = environment.baseUrlApi + "/admin/emails";
    return this.http.get(url);
  }

  sendBulkEmails(emails: any[]): Observable<any> {
    return this.http.post(`${this.baseUrl}/admin/send-emails`, { emails });
  }
}

