import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take, tap } from 'rxjs/operators';
import {AdminService} from './services/admin.service';
import { StorageService } from './services/storage.service';
import { AuthService } from './services/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private refreshInProgress = false;
  private refreshSubject: Subject<any> = new Subject<any>();
  private failedRequests: HttpRequest<any>[] = [];
  ingnoredInterceptedUris = ['factoryUploadTmpDocuments'];


  constructor(private adminService: AdminService,
    private router: Router,
    private storageService: StorageService,
    private authService:AuthService) {
}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let interceptRequest = true;
    this.ingnoredInterceptedUris.forEach((function (ingnoredInterceptedUri) {
        interceptRequest = interceptRequest && !request.url.includes(ingnoredInterceptedUri);
    }));
    request = interceptRequest ? request.clone({
        setHeaders: this.adminService.getToken() ? {
            Authorization: `Bearer ${this.adminService.getToken()}`,
  
        } : {
        }
    }) : request;


    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && (error.status === 403 || error.status === 401 || error.status === 404)) {
          if (this.adminService.getToken() == null) {
            return throwError(error);
          }
          this.failedRequests.push(request);
          if (!this.refreshInProgress) {
            this.refreshToken();
          }
          return this.refreshSubject.pipe(
            filter((token) => token != null),
            take(1),
            switchMap(() => {
              request = request.clone({
                setHeaders: {
                  Authorization: `Bearer ${this.storageService.read(StorageService.TOKEN_STORAGE)}`
                }
              });
              return next.handle(request);
            }),
            catchError((error) => {
              if (error instanceof HttpErrorResponse && (error.status === 403 || error.status === 401 || error.status === 404)) {
                this.failedRequests.push(request);
                if (this.failedRequests.length >= 3) {
                  this.router.navigateByUrl('/auth/login');
                  setTimeout(() => {
                    this.storageService.removeAll()
                  }, 2500);
                        }
              }
              return throwError(error);
            })
          );
        }
        return throwError(error);
      })
    );
  }

  private refreshToken() {
    this.refreshInProgress = true;
    this.refreshSubject.next(null);
    this.authService.refreshToken().pipe(
      tap((data: any) => {
        this.storageService.write(StorageService.TOKEN_STORAGE, data.token);
      }),
      catchError((err) => {
        return throwError(err);
      }),
      finalize(() => {
        this.refreshInProgress = false;
        this.failedRequests.forEach((request) => {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${this.storageService.read(StorageService.TOKEN_STORAGE)}`
            }
          });
          this.retry(request);
        });
        this.failedRequests = [];
      })
    ).subscribe((token) => {
      this.refreshSubject.next(token);
    });
  }

  private retry(request: HttpRequest<any>) {
    this.intercept(request, new HttpHandlerImpl()).subscribe();
  }
}

class HttpHandlerImpl implements HttpHandler {
  handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
    throw new Error('Method not implemented.');
  }
}

