import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ListClientComponent} from './list-admins/list-client.component';
import {ClientFormComponent} from './admins-form/client-form.component';
import { ListOrganizersComponent } from './list-clients/list-organizers.component';
import { LandingPageListComponent } from './landing-page-list/landing-page-list.component';
import { AddClientComponent } from './add-clients/add-client.component';

const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'list-admins',
                component: ListClientComponent
            },
            {
                path: 'add-admin',
                component: ClientFormComponent
            },
            {
                path: ':clientId/edit-client',
                component: ClientFormComponent
            },
            {
                path: 'list-clients',
                component: ListOrganizersComponent
            }, 
            {  
                path: 'list-landing-page',
                component: LandingPageListComponent
            },
            {  
                path: 'add-client',
                component: AddClientComponent
            },
            {  
                path: ':id/modify-client',
                component: AddClientComponent
            }
            
        ]
    }

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ClientRoutingModule {
}
