import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OffreService } from '../../shared/services/offre.service';
import { Offre, OffreType } from '../../shared/models/Offre';
import { Utils } from '../../shared/utils/utils';
import { Subscription } from 'rxjs';
import { Admin } from '../../shared/models/admin/admin';
import { AdminService } from '../../shared/services/admin.service';
import { Menu } from '../../shared/models/Menu';
import { TranslateService } from '@ngx-translate/core';

declare var swal: any;


@Component({
    selector: 'app-add-offre',
    templateUrl: './add-offre.component.html',
    styleUrls: ['./add-offre.component.css']
})
export class AddOffreComponent implements OnInit {
    offre: Offre = new Offre();
    types: OffreType[] = [];
    clients: Admin[] = [];
    busy: Subscription;
    submitted = false;
    isEditMode = false;
    menus: Menu[] = [];
    selectedMenus: Menu[] = [];
    currentMenu: Menu = new Menu();

    constructor(private router: Router,
        private route: ActivatedRoute,
        private offreService: OffreService,
        private adminService: AdminService) {

        this.isEditMode = router.url.indexOf('edit-offre') > 0;
        this.offre.offre_id = +this.route.snapshot.paramMap.get('offre_id');
    }

    ngOnInit(): void {
        this.types = Utils.getOffreType();
        this.getClients();
        this.getMenus();
        this.selectedMenus.push(new Menu());
        if (this.isEditMode) {
            this.setOffre();
        }
    }

    getClients() {
        this.busy = this.adminService.getListClients()
            .subscribe(
                (data: any[]) => {
                    this.clients = data;
                    if (!this.isEditMode) {
                        this.offre.admin_id = this.route.snapshot.paramMap.get('admin_id');
                    }
                },
                (error) => {
                    Utils.sweetAlert('error', 'Une erruer s\'est produite!!', 'error');
                }
            );
    }

    setOffre() {
        this.busy = this.offreService.getOffreById(this.offre.offre_id).subscribe((data: any) => {
            this.offre = data.offre;
            this.selectedMenus = Utils.initMenus(data.menus);
            if (this.selectedMenus.length === 0) {
                this.selectedMenus.push(new Menu());
            }
        });
    }

    isEmptyOffre() {
        return !this.offre.name || !this.offre.start_date || !this.offre.end_date || !this.offre.offre_type_id ||
            !this.offre.admin_id || !this.offre.value;
    }

    submitOffre() {
        this.submitted = true;
        if (this.isEmptyOffre()) {
            Utils.sweetAlert('error', 'Erreur !!', 'Veuillez remplir tous les champs');
            return;
        }
        if (!this.validForm()) {
            Utils.sweetAlert('error', 'Erreur !!', 'Veuillez remplir tous les champs');
            return;
        }
        this.setMenuChildrenIds();
        if (this.isEditMode) {
            this.offre.menus = this.selectedMenus;
            this.busy = this.offreService.editOffre(this.offre, this.offre.offre_id).subscribe(res => {
                Utils.sweetAlert('success', 'Succès !!', 'Offre modifié avec succès');
                this.router.navigate(['manage-offre/list']);
            }, error => {
                Utils.sweetAlert('error', 'Erreur !!', 'Erreur lors de la modification de l\'offre');
            });
        } else {
            this.offre.menus = this.selectedMenus;
            this.busy = this.offreService.addOffre(this.offre).subscribe(res => {
                Utils.sweetAlert('success', 'Succès !!', 'Offre ajouté avec succès');
                this.router.navigate(['manage-offre/list']);
            }, error => {
                Utils.sweetAlert('error', 'Erreur !!', 'Erreur lors de l\'ajout de l\'offre');
            });
        }
    }

    getMenus() {
        this.busy = this.offreService.getAllMenus().subscribe((data: any[]) => {
            this.menus = data;
        });
    }

    addMenu() {
        if (this.selectedMenus.length === 0) {
            this.selectedMenus.push(new Menu());
            return;
        }
        if (!this.validForm()) {
            Utils.sweetAlert('error', 'Erreur !!', 'Veuillez remplir tous les champs');
            return;
        }
        const base = this;
        base.setMenuChildrenIds();
        this.selectedMenus.push(new Menu());
    }

    setMenuChildrenIds() {
        const last = (this.selectedMenus.length - 1);
        if (this.selectedMenus.length > 0) {
            this.currentMenu = this.findMenu(this.selectedMenus[last].menu_id);
        }
        if (this.selectedMenus.length > 0 && this.selectedMenus[last].menu_children_ids
            && this.selectedMenus[last].menu_children_ids.length === 0 && this.currentMenu.menu_children.length !== 0) {
            for (const id of this.selectedMenus[last].menu_children) {
                this.selectedMenus[last].menu_children_ids.push(id.menu_children_id);
            }
        }
    }

    deleteMenu(toDeleteIndex: number) {
        this.selectedMenus.splice(toDeleteIndex, 1);
    }

    validForm() {
        for (let i = 0; i < this.selectedMenus.length; i++) {
            if (!this.selectedMenus[i].menu_id ||
                (this.selectedMenus[i].showMenuChildren && this.selectedMenus[i].menu_children_ids.length === 0)) {
                return false;
            }
        }
        return true;
    }

    findMenu(menuId) {
        return this.menus.find(menu => menu.menu_id.toString() === menuId);
    }

    setChildren(menuId, i) {
        const currentMenu = this.findMenu(menuId);
        if (currentMenu.menu_children.length !== 0) {
            this.selectedMenus[i].menu_children = currentMenu.menu_children;
        }
    }
}
