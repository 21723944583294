import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Utils } from "../../shared/utils/utils";
import { HttpHeaders } from "@angular/common/http";
import { AdminService } from "../../shared/services/admin.service";
import { MailService } from "../../shared/services/mail.service";
import { MailTypeAdmin } from "../../shared/models/Mail_Type_Admin";
import { DataMail, MailAdmin } from "../../shared/models/Mail_Admin";
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { environment } from 'src/environments/environment';

declare var swal: any;
declare var jQuery: any;

@Component({
  selector: 'app-mail-editor',
  templateUrl: './mail-editor.component.html',
  styleUrls: ['./mail-editor.component.css']
})
export class MailEditorComponent implements OnInit {
  editor: any;
  template: string = null;
  titre_mail: string = null;
  mode: string = "";
  mailAdminId: number;
  object: string = null;
  mailTypeAdminId: number;
  attachedFiles: any[] = [];
  pathsAttachments: string[] = [];
  ready:boolean;

  params: ParamMail[] = [];
  mailTypeAdmin: MailTypeAdmin;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: `${environment.baseUrlApi}/files/upload-resource-admin`,
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };



  constructor(private route: ActivatedRoute,
    private router: Router,
    private adminService: AdminService,
    private mailService: MailService) {
  }

  ngOnInit() {
    let base = this;
    //this.mailTypeAdminId = parseInt(this.route.snapshot.paramMap.get('mailTypeAdminId'), 0);
    this.mailAdminId = parseInt(this.route.snapshot.paramMap.get('mailAdminId'), 0);
    console.log(this.mailAdminId);
    this.loadParamMail();

    this.loadMail();
    
  
  }

  /*initialiser l'editeur, et changer la façon avec la quelle il gère l'upload image*/
  onEditorCreated(editorInstance) {
    this.editor = editorInstance;
    const toolbar = this.editor.getModule('toolbar');
    toolbar.addHandler('image', this.imageHandler);
  }

  //fonction upload image

  imageHandler = (image, callback) => {
    const input = <HTMLInputElement>document.getElementById('fileInputField');
    document.getElementById('fileInputField').onchange = () => {
      let file: File;
      file = input.files[0];
      const formData = new FormData();
      formData.append('image', input.files[0], input.files[0].name);
      this.adminService.uploadImageMail(formData).subscribe(
        (res) => {
          const range = this.editor.getSelection();
          const img = '<img src="' + res.link + '" />';
          this.editor.clipboard.dangerouslyPasteHTML(range.index, img, "user");
        }
      )
    };

    input.click();
  }

  loadMail() {
    this.mailService.getMailAdminByMailTypeAdminId(this.mailAdminId)
      .subscribe(
        (data: MailAdmin) => {
          if (data) {
            console.log(data);
            this.object = data.object;
            this.titre_mail = data.titre_mail;
            this.template = data.template;
            this.mailAdminId = data.mail_admin_id;
            this.mailTypeAdmin = data.type;
            
            // Assurez-vous que data.attachments est une chaîne JSON valide
            try {
              // Vérifie si data.attachments est déjà un tableau, sinon parse-le
              this.ready = true;
              this.pathsAttachments = Array.isArray(data.attachments) 
                ? data.attachments 
                : JSON.parse(data.attachments);
                console.log(this.pathsAttachments);
            } catch (error) {
              console.error('Erreur lors de l\'analyse des pièces jointes:', error);
              this.pathsAttachments = []; // Assignation d'un tableau vide en cas d'erreur
            }
          }
          
        },
        (error) => {
          console.error('Erreur lors du chargement du mail', error);
          this.router.navigateByUrl('/');
        }
      );
}



  loadParamMail() {
    this.params = [
      {
        label: 'Nom du participant',
        value: '{{$user->last_name}}',
        visible: true
      },
      {
        label: 'Prénom du participant',
        value: '{{$user->first_name}}',
        visible: true
      },
      {
        label: 'Mr./Mme.',
        value: '{{$user->gender}}',
        visible: this.mode !== 'organization'
      },
      {
        label: 'Nom admin',
        value: '{{$admin->name}}',
        visible: true
      },
      {
        label: 'Email admin',
        value: '{{$admin->email}}',
        visible: true
      },
      {
        label: 'Password admin',
        value: '{{$admin->passwordDecrypt}}',
        visible: true
      },
      {
        label: "Eventizer BackOffice",
        value: "<{{$linkBackOffice}}",
        visible: true,
      },
      {
        label: "Lien d'activation",
        value: "<{{$activationLink}}",
        visible: true
      },
      {
        label: 'Lien de paiement',
        value: '<{{$paymentLink}}',
        visible: true
      },
      {
        label: 'Nom contact',
        value: '{{$contact->user_name}}',
        visible: true
      },
      {
        label: 'Contact email',
        value: '{{$contact->email}}',
        visible: true
      },
      {
        label: 'Téléphone de contact',
        value: '{{$contact->mobile}}',
        visible: true
      }
    ]
  }

  saveMail() {
    let base = this;
    if (!this.template || !this.object) {
      Utils.sweetAlert("warning", "Attention!", "Vérifiez que l'objet ainsi que le contenu du mail ne sont pas vides!");
    } else {
      // Vérifiez si mailAdminId existe, si oui, appelez updateMail, sinon createMail
      const attachments: string[] = [];
      // Collect the full paths using forEach
      this.attachedFiles.forEach((file) => {
       attachments.push(environment.filesUrl + file.path); // Construct the full path
       this.pathsAttachments.push(environment.filesUrl + file.path);
      });
      if (this.mailAdminId) {
        this.adminService.updateMail(this.mailAdminId, this.object, this.template,this.titre_mail,attachments).subscribe(data => {
          swal({ title: 'Succès', text: 'Changements effectués avec succès', type: 'success' })
            .then(() => {
              if (this.mailTypeAdminId === 5) {
                base.router.navigateByUrl("/manage-mail/personalize");
              } else {
                base.router.navigateByUrl("/manage-mail/list");
              }
            });
        });
      } else {
        this.adminService.saveMail(this.object, this.template,this.titre_mail,attachments).subscribe(data => {
          swal({ title: 'Succès', text: 'Mail créé avec succès', type: 'success' })
            .then(() => {
              if (this.mailTypeAdminId === 5) {
                base.router.navigateByUrl("/manage-mail/personalize");
              } else {
                base.router.navigateByUrl("/manage-mail/list");
              }
            });
        });
      }
    }
  }


  insertInEditor(text: string) {
    const selection = this.editor.getSelection(true);
    if (text.charAt(0) === '<') {
      this.editor.insertText(selection.index, 'Link', 'link', text.substring(1), "user");
    } else {
      this.editor.insertText(selection.index, text, "user");
    }
    this.editor.setSelection(selection.index + text.length, 0, "user");
  }

  // Méthode pour ajouter un fichier comme pièce jointe lors de l'upload
  onUploadImageAttachments(e: any): void {
    const index = this.attachedFiles.findIndex((file) => {
      return file.file_name === e.fileName;
    });

    if (index > -1) {
      this.attachedFiles[index].resource_id = e.resource.resource_id;
    } else {
      this.attachedFiles.push({
        path: e.resource.path,
        resource_id: e.resource.resource_id,
        file_name: e.fileName
      });
    }

    this.pathsAttachments.push(environment.filesUrl + e.resource.path);
  }

  // Méthode pour supprimer un fichier de la liste des pièces jointes
  onDeleteImageAttachments(e: any): void {
    const index = this.attachedFiles.findIndex((file) => {
      return file.resource_id === e.responseJSON.resource_id;
    });

    if (index > -1) {
      this.attachedFiles.splice(index, 1);  // Supprime l'élément de attachedFiles
      this.pathsAttachments.splice(index, 1);  // Supprime le chemin correspondant pour l'aperçu
    }
  }
}

export class ParamMail {
  label: string;
  visible: boolean;
  value: string;
}
