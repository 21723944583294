import {Component, OnInit} from '@angular/core';
import {AdminService} from '../../shared/services/admin.service';
import {Subscription} from 'rxjs';
import {Router, ActivatedRoute} from '@angular/router';
import {Admin} from 'src/app/shared/models/admin/admin';
import {Utils} from 'src/app/shared/utils/utils';

@Component({
    selector: 'app-client-form',
    templateUrl: './client-form.component.html',
    styleUrls: ['./client-form.component.css']
})
export class ClientFormComponent implements OnInit {
    admin: Admin = new Admin();
    busy: Subscription;
    submitted = false;
    checked: boolean;
    isEditMode = false;
    showPassword = false;
    constructor(private adminService: AdminService,private router: Router,private route: ActivatedRoute)
    {
        this.isEditMode = router.url.indexOf('edit-client') > 0;
        this.admin.admin_id = +this.route.snapshot.paramMap.get('clientId');
    }

    ngOnInit() {
        if (this.isEditMode) {
            this.setClient();
        }
    }

    generatePassword() {
        this.admin.passwordDecrypt = Utils.randomPasswordGenerate(12, 12);
    }

    toggleShowPassword() {
        this.showPassword = !this.showPassword;  // Toggle the password visibility
    }

    isEmptyAdmin() {
        if (!this.admin.name || !this.admin.email || !this.admin.mobile) {
            return true;
        }
        return false;
    }
    setClient() {
        this.adminService.getClientById(this.admin.admin_id)
            .subscribe( (data: any) => {
                this.admin=data;
                if (this.admin.valid_date){this.checked=true;}
            }, (error) => {
            });
    }
    submitClient() {
        this.submitted = true;
        if (this.isEmptyAdmin()) {
            Utils.sweetAlert('error', 'Erreur !!', 'Remplir les champs');
            return;
        }
        if (this.isEditMode){
            this.busy = this.adminService.editClient(this.admin)
                .subscribe(
                    (data) => {
                        Utils.sweetAlert('success', 'Succées', 'Modification admin avec succées');
                        this.router.navigate(['/admins/list-admins']);
                    },
                    (error) => {
                        if (error.status === 405) {
                            Utils.sweetAlert("error", 'Erreur', "Une erreur est survenue");
                        } else {
                            Utils.sweetAlert("error", 'Erreur', "Une erreur est survenue");
                        }
                    }
                );
        }
        else {
            this.busy = this.adminService.addClient(this.admin)
                .subscribe(
                    (data) => {
                        Utils.sweetAlert('success', 'Succéss', 'Ajout client avec succées');
                        this.router.navigate(['/admins/list-admins']);
                    },
                    (error) => {

                        if (error.status === 402) {
                            Utils.sweetAlert('error', 'Erreur', 'Email existe déja');
                        } else {
                            Utils.sweetAlert('error', 'Erreur', 'Erreur est survenu');
                        }

                    }
                );
        }
    }
}

