<div class="content">
    <div class="card">
        <div class="card-header header-elements-inline" [ngBusy]="busy">
            <h6 class="card-title">Liste des demandes</h6>
        </div>
        <br>
        <div class="card-body">
            <table class="table landing-table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Nom du Client </th>
                        <th>Nom de l'event</th>
                        <th>DNS </th>
                        <th>DNS PWA</th>
                        <th>Status de demande</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let landingPage of landingPages; let i=index;">
                        <td>
                            {{landingPage.admin_id}}
                        </td>
                        <td>
                            {{landingPage.admin.name}}
                        </td>
                        <td>
                            {{landingPage.congress.name}}
                        </td>
                        <td>
                            {{landingPage.dns}}
                        </td>
                        <td>
                            {{landingPage.dns_pwa}}
                        </td>
                        <td>
                            <div class="btn-group dropdown" [ngSwitch]="landingPage.status">
                                <a *ngSwitchCase="1" class="badge bg-green align-top ">
                                    <i class="icon-checkmark3"></i> accepté
                                </a>
                                <a *ngSwitchCase="0" class="badge bg-grey align-top">
                                    <i class="icon-warning"></i> En cours
                                </a>
                                <a *ngSwitchCase="-1" class="badge bg-warning align-top">
                                    <i class="icon-diff-removed"></i> refusé
                                </a>
                            </div>
                        </td>
                        <td>
                            <button type="button" *ngIf="landingPage.status ==1 " class="btn btn-success btn-xs"
                                (click)="navigateToLandingPageFront(landingPage.congress_id)"><i
                                    class="icon-eye position-left"></i> {{ 'Preview' | translate }}
                            </button>
                            <div class="list-icons" *ngIf="landingPage.status==0 || landingPage.status==-1">
                                <div class="dropdown">
                                    <a href="#" class="list-icons-item" data-toggle="dropdown">
                                        <i class="icon-menu9"></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                        <a class="dropdown-item"
                                        (click)="navigateToLandingPageFront(landingPage.congress_id)">
                                        <i class="icon-eye position-left"></i>{{ 'Preview' | translate }}
                                    </a>
                                        <a class="dropdown-item"
                                            (click)="upadteStatusLandingPage(landingPage.request_landing_page_id,1, i)">
                                            <i class="icon-database-edit2"></i>Accepter
                                        </a>
                                        <a class="dropdown-item"
                                            (click)="upadteStatusLandingPage(landingPage.request_landing_page_id,-1, i)">
                                            <i class="icon-database-remove"></i>Refuser
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>