/**
 * Created by Abbes on 07/10/2017.
 */
import { Injectable } from "@angular/core";
import { GenericService } from "./generic.service";
import { HttpClient } from "@angular/common/http";
import { AdminService } from "./admin.service";
import { environment } from "../../../environments/environment";

@Injectable()
export class MailService extends GenericService {


    constructor(private http: HttpClient, private adminService: AdminService) {
        super();
    }

    getAllMailType() {
        const headers = this.headers.set("Authorization", "Bearer " + this.adminService.getToken());
        return this.http.get(environment.baseUrlApi +'/admin/mailtype', {
            headers: headers
        });
    }

    getMailAdminByMailTypeAdminId(mailTypeAdminId: number){
        const headers = this.headers.set("Authorization", "Bearer " + this.adminService.getToken());
        return this.http.get(environment.baseUrlApi + '/admin/mail/' + mailTypeAdminId,{
            headers: headers
        });
    }
    getMailTypeAdminByMailTypeAdminId(mailTypeAdminId: number){
        const headers = this.headers.set("Authorization", "Bearer " + this.adminService.getToken());
        return this.http.get(environment.baseUrlApi + '/admin/mailtype/' + mailTypeAdminId,{
            headers: headers
        });
    }
}
