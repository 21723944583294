import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import { Category } from '../models/Category';


@Injectable({
    providedIn: 'root'
})
export class CategoryService {


    constructor(private http: HttpClient) {
    }

    addCategory(category: Category) {
        const url = environment.baseUrlApi + '/category/add';
        return this.http.post(url, category);
    }

    getAllCategories() {
        const url = environment.baseUrlApi + '/category/list';
        return this.http.get(url);
    }

}