import {Injectable} from '@angular/core';
import {GenericService} from './generic.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {AdminService} from './admin.service';
import {Offre} from '../models/Offre';


@Injectable()
export class OffreService extends GenericService {


    constructor(private http: HttpClient, private adminService: AdminService) {
        super();
    }

    getAllOffres() {
        const url = environment.baseUrlApi + '/offre/list';
        return this.http.get(url);
    }

    addOffre(offre: Offre) {
        const url = environment.baseUrlApi + '/offre/add';
        return this.http.post(url, offre);
    }

    getOffreById(offre_id) {
        const url = environment.baseUrlApi + '/offre/get/' + offre_id;
        return this.http.get(url);
    }

    editOffre(offre: Offre, offre_id) {
        const url = environment.baseUrlApi + '/offre/edit/' + offre_id;
        return this.http.put(url, offre);
    }

    getAllMenus() {
        const url = environment.baseUrlApi + '/menu/list';
        return this.http.get(url);
    }

}
