import {NgModule} from '@angular/core';
import {LoginComponent} from './login/login.component';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import {AuthPageComponent} from './auth-page/auth-page.component';
import {ManageUserRoutingModule} from './manage-user-routing.module';

@NgModule({
    declarations: [
        LoginComponent,
        AuthPageComponent
    ],
    imports: [
        ManageUserRoutingModule,
        SharedModule
    ]
})
export class ManageUserModule {
}
