import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {TokenInterceptor} from './shared/token.interceptor';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CommonModule, HashLocationStrategy, LocationStrategy} from '@angular/common';
import {SharedModule} from './shared/shared.module';
import {LayoutsModule} from './layouts/layouts.module';
import {ErrorModule} from './error/error.module';
import {ManageUserModule} from './manage-user/manage-user.module';
import {CanActivateViaAuthGuard} from './shared/services/guards/auth-guard.service';
import {StorageService} from './shared/services/storage.service';
import {AdminService} from './shared/services/admin.service';
import {NotAuthorizedAdminGuard} from './shared/services/guards/not-authorized-admin-guard';
import {CanActivateViaAuthSuperAdminAuth} from './shared/services/guards/auth-super-admin-guard';
import {LoadPageService} from './shared/services/load-page.service';
import {CustomerService} from './shared/services/customer.service';
import {AuthGuard} from './shared/guards/auth.guard';
import {MailService} from './shared/services/mail.service';
import {ManageMailModule} from './manage-mail/manage-mail.module';
import {ManageOffreModule} from './manage-offre/manage-offre.module';
import {OffreService} from './shared/services/offre.service';
import {TranslateModule, TranslateStore} from '@ngx-translate/core';
import { LandingPageService } from './shared/services/landing-page.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ManageMenuModule } from './manage-menu/manage-menu.module';
import { ManageProjectsModule } from './manage-projects/manage-projects.module';
import { ManageUsersModule } from './manage-users/manage-users.module';
import { AddClientComponent } from './client/add-clients/add-client.component';
import { ClientModule } from './client/client.module';
import { ExcelUploadComponent } from './excel-upload/excel-upload.component';


@NgModule({
    declarations: [
        AppComponent,
        AddClientComponent,
        ExcelUploadComponent,

       
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        SharedModule,
        AppRoutingModule,
        HttpClientModule,
        LayoutsModule,
        ErrorModule,
        ManageUserModule,
        CommonModule,
        ClientModule,
        ManageMailModule,
        ManageOffreModule,
        TranslateModule,
        ManageMenuModule,
        ManageProjectsModule,
        ManageUsersModule,

    ],
    providers: [
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },

        CanActivateViaAuthGuard,
        NotAuthorizedAdminGuard,
        StorageService,
        AdminService,
        CustomerService,
        CanActivateViaAuthSuperAdminAuth,
        LoadPageService,
        StorageService,
        AuthGuard,
        MailService,
        OffreService,
        TranslateStore,
        LandingPageService

    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
