import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Admin } from 'src/app/shared/models/admin/admin';
import { Category } from 'src/app/shared/models/Category';
import { Project } from 'src/app/shared/models/Project';
import { AdminService } from 'src/app/shared/services/admin.service';
import { CategoryService } from 'src/app/shared/services/category.service';
import { ProjectService } from 'src/app/shared/services/project.service';
import { Utils } from 'src/app/shared/utils/utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.css']
})
export class AddProjectComponent implements OnInit {
  project: Project = new Project();
  category: Category = new Category();
  submitted: boolean = false;
  clients: Admin[] = [];
  busy: Subscription;
  showAddCategory: boolean = false;
  categories: Category[] = [];
  isEdit: boolean
  pathsBanner: string[] = [];
  baseUrl: string = environment.filesUrl;
  ready: boolean;


  constructor(public adminService: AdminService,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private categoryService: CategoryService,
    private router: Router,
  ) {
    this.isEdit = this.route.snapshot.paramMap.get('project_id') ? true : false;
    this.project.project_id = +this.route.snapshot.paramMap.get('project_id');

  }

  ngOnInit(): void {
    this.getClients()
    this.getAllCategories()
    if (this.isEdit) {
      this.setProject();
    }
  }

  getClients() {
    this.busy = this.adminService.getListClients()
      .subscribe(
        (data: any[]) => {
          this.clients = data;
        },
        (error) => {
          Utils.sweetAlert('error', 'Une erruer s\'est produite!!', 'error');
        }
      );
  }

  addProject() {
    this.submitted = true;
    if (!this.isChampsValid()) {
      Utils.sweetAlert('error', 'remplir  les champs obligatoires', 'error');
      return;
    }
    this.busy = this.projectService.addProject(this.project).subscribe(res => {
      Utils.sweetAlert('success', 'Succès !!', 'Projet ajoutée avec succès');
      this.router.navigate(['/manage-projects/list']);
    }, error => {
      Utils.sweetAlert('error', 'Erreur !!', 'Erreur lors de l\'ajout de projet');
    });
  }

  showAdd() {
    this.showAddCategory = true;
  }

  addCategory() {
    if (!this.category.label) {
      Utils.sweetAlert('error', 'Erreur lors de la ajout', 'Label de categorie est obligatoire !!');
      return;
    }
    this.busy = this.categoryService.addCategory(this.category).subscribe((data: Category[]) => {
      this.showAddCategory = false;
      this.categories = data;
      this.category = new Category();
      Utils.sweetAlert('success', 'Succès !!', 'Categorie ajoutée avec succès');
    }, error => {
      Utils.sweetAlert('error', 'Erreur !!', 'Erreur lors d\'ajout de l\'category');
    });
  }

  getAllCategories() {
    this.busy = this.categoryService.getAllCategories().subscribe((data: Category[]) => {
      this.categories = data;
    });
  }

  setProject() {
    this.busy = this.projectService.getProjecteById(this.project.project_id).subscribe((data: any) => {
      if (data.project_img) {
        this.pathsBanner.push(this.baseUrl + data.project_img);
      }
      this.project = data;
      this.ready = true;

    });
  }

  onUploadImg(e) {
    this.project.project_img = e.resource.path;
  }

  onDeleteImg(e) {
    this.project.project_img = null;
  }

  isChampsValid() {
    if (this.project.nom && this.project.date && this.project.category_id && this.project.admin_id) {
      return true;
    }
    return false;
  }

}
