import {NgModule} from '@angular/core';
import {FullLayoutComponent} from './full-layout.component';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';

@NgModule({
    declarations: [
        FullLayoutComponent
    ],
    imports: [
        RouterModule,
        SharedModule
    ]
})
export class LayoutsModule {
}
