import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotFoundComponent} from './error/not-found/not-found.component';
import {FullLayoutComponent} from './layouts/full-layout.component';
import {AuthPageComponent} from './manage-user/auth-page/auth-page.component';
import {NotAuthorizedAdminGuard} from './shared/services/guards/not-authorized-admin-guard';
import {AuthGuard} from './shared/guards/auth.guard';

export const routes: Routes = [
    {
        path: '',
        component: FullLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'admins',
                loadChildren: () => import('./client/client.module').then(m => m.ClientModule)
            },
            {
                path: 'manage-mail',
                loadChildren: () => import('./manage-mail/manage-mail.module').then(m => m.ManageMailModule)
            },
            {
                path: 'manage-offre',
                loadChildren: () => import('./manage-offre/manage-offre.module').then(m => m.ManageOffreModule)
            },
            {
                path: 'manage-menu',
                loadChildren: () => import('./manage-menu/manage-menu.module').then(m => m.ManageMenuModule)
            },
            {
                path: 'manage-projects',
                loadChildren: () => import('./manage-projects/manage-projects.module').then(m => m.ManageProjectsModule)
            },
            {
                path: 'manage-users',
                loadChildren: () => import('./manage-users/manage-users.module').then(m => m.ManageUsersModule)
            },
            {
                path: 'manage-configuration',
                loadChildren: () => import('./configuration/configuration.module').then(m => m.ConfigurationModule)
            },
        ],
    },
    {
        path: 'auth',
        loadChildren: () => import('./manage-user/manage-user.module').then(m => m.ManageUserModule),
        canActivate: [NotAuthorizedAdminGuard]
    },
    {
        path: 'admin/auth',
        component: AuthPageComponent,
        canActivate: [NotAuthorizedAdminGuard]
    },
    {path: '**', component: NotFoundComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
