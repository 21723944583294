import { environment } from '../../../environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subscription } from 'rxjs';
import { Admin } from 'src/app/shared/models/admin/admin';
import { AdminService } from 'src/app/shared/services/admin.service';
import { ClientService } from 'src/app/shared/services/client.service';
import { ParticipantService } from 'src/app/shared/services/participant.service';
import { DatatablesUtils } from "src/app/shared/datatable-utils";
import { Utils } from "src/app/shared/utils/utils";
import { TranslateService } from '@ngx-translate/core';

declare var jQuery: any;

@Component({
  selector: 'app-list-organizers',
  templateUrl: './list-organizers.component.html',
  styleUrls: ['./list-organizers.component.css']
})
export class ListOrganizersComponent implements OnInit {
  clients: any[] = [];
  busy: Subscription;
  dtOptions: any = {};
  perPage: number = 9;
  page: number = 1;
  dtElement: DataTableDirective;
  @ViewChild(DataTableDirective, { static: true })
  tri: string;
  order: string;
  ready: boolean = false;
  emailList: string[] = []; // Liste des e-mails disponibles
  selectedEmails: string[] = []; // E-mails sélectionnés par l'utilisateur
  selectedClients: string[] = [];
  emailSubject: string = ''; // Sujet de l'email
  emailBody: string = ''; // Corps du message
  currentClientEmail: string;
  isSending: boolean = false;
  isSendingMasse: boolean = false;

  constructor(public clientService: ClientService, private router: Router, private translate: TranslateService) { }

  ngOnInit() {
    this.getallclient();
    this.openEmailModalMasse();
  }

  getallclient() {
    const that = this;
    this.dtOptions = {
      dom: "['<l>', Bfrtip']",
      buttons: ["colvis"],
      pagingType: "full_numbers",
      pageLength: 10,
      serverSide: true,
      aLengthMenu: [
        [5, 10, 25, 50, 100000],
        [5, 10, 25, 50, "All"],
      ],
      ajax: (dataTablesParameters: any, callback) => {
        const page = (dataTablesParameters.start / dataTablesParameters.length + 1);
        const perPage = dataTablesParameters.length;
        const search = dataTablesParameters.search.value;

        that.busy = that.clientService
          .getListClients(perPage, page, search)
          .subscribe(
            (data: any) => {
              console.log(data);
              this.clients = data.data;
              callback({
                recordsTotal: data.to,
                recordsFiltered: data.total,
                data: that.clients,
              });
            },
            (error) => { }
          );
      },
      columns: that.getColumns(),
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        jQuery(".action-btn", row).unbind("click");
        jQuery(".action-btn", row).bind("click", (event) => {
          const actionId = parseInt($(event.target).attr("action-id"), 0);
          this.goToAction(actionId, data);
        });
      },
    };
  }

  getColumns() {
    const columns = [
      { data: "company_name", visible: true, title: "Nom de l'Entreprise" },
      { data: "director_name", visible: true, title: "Nom du dirigeant" },
      { data: "phone", visible: true, title: "Téléphone" },
      { data: "email", visible: true, title: "Email" },
      { data: "address", visible: true, title: "Adresse" },
      { data: "activity_sector", visible: true, title: "Secteur d'activité" },
      { data: "siret", visible: true, title: "Siret" },
      /* { data: "idcc", visible: true, title: "IDCC" },
      { data: "code_naf", visible: true, title: "Code NAF" },
      { data: "creation_date", visible: true, title: "Date de création" },
      { data: "urssaf_identifiant", visible: true, title: "URSSAF Identifiant" },
      { data: "impots_identifiant", visible: true, title: "IMPOTS Identifiant" },
      { data: "retraite_identifiant", visible: true, title: "RETRAITE Identifiant" },
      { data: "prevoyance_identifiant", visible: true, title: "PREVOYANCE Identifiant" },
      { data: "mutuelle_identifiant", visible: true, title: "MUTUELLE Identifiant" },*/
      {
        data: "actions",
        visible: true,
        render: function () {
          return DatatablesUtils.renderActionsClientBtn();
        },
      },
    ];
    return columns;
  }

  private goToAction(actionId: number, data) {
    switch (actionId) {
      case 1: {
        this.router.navigate(["/admins/" + data.id + "/modify-client"]);
        break;
      }
      case 2: {
        this.removeClient(data.id);
        break;
      }
      case 3: { // Envoyer un Email
        this.openEmailModal(data.email); // Ouvrir la modale d'envoi d'email avec l'email du client
        break;
      }
    }
  }

  openEmailModal(clientEmail: string) {
    // Fetch all client emails
    this.busy = this.clientService.getAllClientEmails().subscribe((response: any) => {
      this.emailList = response.map((emailObj: any) => emailObj.object);
      this.emailBody = response.map((emailObj: any) => emailObj.template);
      this.selectedEmails = []; // Réinitialiser la sélection
      this.currentClientEmail = clientEmail; // Stocker l'e-mail du client courant
      this.showEmailModal(); // Afficher le modal
    });
  }

  openEmailModalMasse() {
    // Fetch all client emails
    this.busy = this.clientService.getAllClientEmails().subscribe((response: any) => {
      this.emailList = response.map((emailObj: any) => emailObj.object);
      this.emailBody = response.map((emailObj: any) => emailObj.template);
      this.selectedEmails = []; // Réinitialiser la sélection
    });
  }


  showEmailModal() {
    // Ouvrir le modal
    jQuery('#emailModal').modal('show');
  }


  removeClient(client_id) {
    this.busy = this.clientService.removeClientById(client_id).subscribe(
      (data) => {

        Utils.sweetAlert('success', 'Succées', 'Suppression client avec succées');
        setTimeout(() => {
          window.location.reload();
        }, 2000); // 2000 milliseconds = 2 seconds

      }, error => {
        Utils.sweetAlert('error', 'Erreur', 'Erreur lors de la suppression');
      });
  }

  sendSelectedEmails() {
    if (this.isSending) return;
  
    // Vérifier si des emails sont sélectionnés
    if (this.selectedEmails.length === 0) {
      Utils.sweetAlert('error', 'Erreur', 'Veuillez sélectionner au moins un email.');
      return; // Sortir de la fonction si aucun email n'est sélectionné
    }
  
    this.isSending = true; // Démarrer l'envoi
  
    // Créer un tableau pour stocker les données des e-mails à envoyer
    const emailsToSend = this.selectedEmails.map(email => {
      // Trouver l'index de l'e-mail sélectionné dans emailList
      const emailIndex = this.emailList.indexOf(email);
      console.log(emailIndex);
      // Vérifier si l'index est valide
      if (emailIndex !== -1) {
        return {
          recipient: this.currentClientEmail, // Utiliser l'e-mail du client courant
          subject: email, // Objet de l'e-mail (l'email sélectionné)
          body: this.emailBody[emailIndex] // Corps de l'e-mail correspondant
        };
      }
    }).filter(Boolean); // Filtrer les valeurs undefined
  
    // Envoyer les e-mails
    this.busy = this.clientService.sendBulkEmails(emailsToSend).subscribe(response => {
      jQuery('#emailModal').modal('hide');
      Utils.sweetAlert('success', 'Succès', 'Mails envoyés avec succès');
    }, error => {
      console.error('Erreur lors de l\'envoi des e-mails:', error);
    }, () => {
      this.isSending = false; // Réinitialiser l'état après l'envoi
    });
    
  }
  
  
  
  toggleEmailSelection(email: string, isChecked: boolean) {
    if (isChecked) {
      this.selectedEmails.push(email);
    } else {
      this.selectedEmails = this.selectedEmails.filter(e => e !== email);
    }
  }

  toggleClientSelection(clientEmail: string, isChecked: boolean) {
    // Si la case est cochée, ajouter l'email du client à la liste selectedClients
    if (isChecked) {
      this.selectedClients.push(clientEmail);
    } 
    // Sinon, retirer l'email du client de la liste selectedClients
    else {
      this.selectedClients = this.selectedClients.filter(email => email !== clientEmail);
    }
  }
  
  
  sendSelectedEmailsMasse() {
    // Vérifier s'il y a des emails et des utilisateurs sélectionnés
    console.log(this.selectedEmails);
    console.log(this.selectedClients);
    if (this.selectedEmails.length === 0 || this.selectedClients.length === 0) {
      Utils.sweetAlert('error', 'Erreur', 'Veuillez sélectionner au moins un modèle d\'email et un utilisateur.');
      return;
    }
    this.isSendingMasse = true;
    // Créer un tableau d'emails à envoyer
    const emailsToSend = [];
  
    // Parcourir chaque utilisateur sélectionné
    this.selectedClients.forEach(clientEmail => {
      // Pour chaque utilisateur, associer chaque modèle d'email sélectionné
      this.selectedEmails.forEach(emailTemplate => {
        const emailIndex = this.emailList.indexOf(emailTemplate); // Trouver l'index du template d'email
        if (emailIndex !== -1) {
          emailsToSend.push({
            recipient: clientEmail, // L'adresse email de l'utilisateur
            subject: emailTemplate, // Le sujet de l'email (modèle sélectionné)
            body: this.emailBody[emailIndex] // Le corps du message correspondant au modèle sélectionné
          });
        }
      });
    });
  
    // Appeler le service pour envoyer les emails en masse
    this.busy = this.clientService.sendBulkEmails(emailsToSend).subscribe(response => {
      // Fermer le modal
      jQuery('#bulkEmailModal').modal('hide');
      // Message de succès
      Utils.sweetAlert('success', 'Succès', 'Les emails ont été envoyés avec succès.');
    }, error => {
      // Gestion des erreurs
      console.error('Erreur lors de l\'envoi des emails en masse :', error);
      Utils.sweetAlert('error', 'Erreur', 'Une erreur est survenue lors de l\'envoi des emails.');
    }, () => {
      this.isSendingMasse = false; // Réactiver les boutons une fois terminé
    });
  }

  toggleSelectAllClients(isChecked: boolean) {
    if (isChecked) {
      // Si la case est cochée, ajouter tous les emails des clients à la liste des sélectionnés
      this.selectedClients = this.clients.map(client => client.email);
    } else {
      // Si la case est décochée, vider la liste des clients sélectionnés
      this.selectedClients = [];
    }
  }

  toggleSelectAllEmails(isChecked: boolean) {
    if (isChecked) {
      // Si la case est cochée, ajouter tous les emails à la liste des sélectionnés
      this.selectedEmails = this.emailList.slice();  // Copier tous les modèles d'emails
    } else {
      // Si la case est décochée, vider la liste des emails sélectionnés
      this.selectedEmails = [];
    }
  }
  

      
}