import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import { Project } from '../models/Project';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class ProjectService {


    constructor(private http: HttpClient) {
    }

    addProject(project: Project) {
        const url = environment.baseUrlApi + '/project/add';
        return this.http.post(url, project);
    }

    getAllProjects() {
        const url = environment.baseUrlApi + '/project/list';
        return this.http.get(url);
    }

    getProjecteById(project_id) {
        const url = environment.baseUrlApi + '/project/get/' + project_id;
        return this.http.get(url);
    }

    deleteProject(project_id: number): Observable<Project[]> {
        const url = environment.baseUrlApi + '/project/delete/' + project_id;
        return this.http.delete<Project[]>(url
        );
    }

}