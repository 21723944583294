<div class=" dropdown">
  <a class="dropdown-toggle btn btn-default" data-toggle="dropdown">
    {{title}}
    <i class="caret"></i>
  </a>

  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
    <li *ngFor="let option of options;let i=index" class="checkbox">
      <label><input value="{{ids[i]}}"
                    type="checkbox"
                    class="styled {{className}}">
        <img src="{{icons[i]}}" class="icon-class"/>
        {{option}}</label>
    </li>
  </ul>
</div>
