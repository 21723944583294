import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ManageMenuRoutingModule } from './manage-menu-routing.module';
import { ManageMenuComponent } from './manage-menu.component';



@NgModule({
    declarations: [
        ManageMenuComponent
    ],
    imports: [
        ManageMenuRoutingModule,
        SharedModule
    ]
})
export class ManageMenuModule {
}