import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Menu } from '../models/Menu';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(private http: HttpClient) { }

  getAllMenu(show_after_reload) {
    const url = environment.baseUrlApi + '/menu/all/'+show_after_reload;
    return this.http.get(url);
}

addMenu( Menus: Menu[],show_after_reload): Observable<Menu[]> {
  return <Observable<Menu[]>>this.http.post(environment.baseUrlApi + '/menu/add/'+show_after_reload , Menus);
}
}
