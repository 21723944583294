import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ListClientComponent} from './list-admins/list-client.component';
import {ClientRoutingModule} from './client-routing.module';
import {SharedModule} from '../shared/shared.module';
import {ClientFormComponent} from './admins-form/client-form.component';
import { ListOrganizersComponent } from './list-clients/list-organizers.component';
import { DataTablesModule } from 'angular-datatables';
import { FormsModule } from '@angular/forms';
import { LandingPageListComponent } from './landing-page-list/landing-page-list.component';
import {TranslateModule, TranslateStore} from '@ngx-translate/core';
import { ParticipantService } from '../shared/services/participant.service';


@NgModule({
    declarations: [ListClientComponent, ClientFormComponent,  LandingPageListComponent,ListOrganizersComponent],
    imports: [
        CommonModule,
        ClientRoutingModule,
        SharedModule,
        FormsModule,
        DataTablesModule,
        TranslateModule,
        ClientRoutingModule,
    ],
    providers: [
        TranslateStore,
        ParticipantService,
    ],
})
export class ClientModule {
}
