import {Admin, AdminPayment} from './admin/admin';
import {Menu, MenuChildrenOffre} from './Menu';


export class Offre {
    offre_id: number;
    name: string;
    value: number;
    start_date: Date = null;
    end_date: Date = null;
    status: number;
    admin_id: string;
    offre_type_id: string;
    type: OffreType = new OffreType();
    payment_admin: AdminPayment = new AdminPayment();
    is_mail_pro: number = 0;
    admin: Admin;
    menus: Menu[] = [];
    menu_children_offre: MenuChildrenOffre[];
}

export class OffreType {
    offre_type_id: number;
    name: string;
}
