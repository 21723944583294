<input class="{{className}}" data-theme="explorer-fas" multiple name="files" type="file"
(change)="setPhoto($event)" [ngClass]="{'custom-file-input': uploadType === 'croppie',
'file-styled': uploadType === 'normal'}" accept="{{accept}}">
<div *ngIf="previewFiles && previewFiles.length > 0">
    <div *ngFor="let file of previewFiles; let i = index" class="uploaded-file">
      <!--span>{{ file }}</span>
      <button (click)="file" class="btn-download">Télécharger</button-->
        <a [href]="file" target="_blank" [download]="file" class="download-file">
          <i *ngIf="isExcelFile(file)" class="icomoon-free--file-excel"></i>
          <i *ngIf="isExcelFile(file)" class="icon-download"></i>
          </a>
          
    </div>
  </div>
