import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from "rxjs";
import {AdminService} from "../services/admin.service";

declare const swal: any;

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private adminService: AdminService, private router: Router) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      const url: string = state.url;

    if (this.adminService.getToken())
    {
      return this.checkLogin(url);}
    else
      {this.router.navigateByUrl('/auth/login');}
      
  }
  checkLogin(url: string): boolean {
    if (this.adminService.getLoggedAdmin() != null) {
      return true;
    }
    // Navigate to the login page with extras
    this.router.navigateByUrl('/auth/login');
    return false;
  }
}
