import { environment } from './../../../environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subscription } from 'rxjs';
import { User } from 'src/app/shared/models/User';
import { ParticipantService } from 'src/app/shared/services/participant.service';
import { Utils } from 'src/app/shared/utils/utils';

declare var jQuery: any;

@Component({
  selector: 'app-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.css']
})

export class ListUsersComponent implements OnInit {
  busy: Subscription;
  congressId: number=  null;
  dtOptions: any = {};
  search: string = "" ;
  perPage: number = 9;
  page: number = 1;
  @ViewChild(DataTableDirective, {static: true})
  dtElement: DataTableDirective;
  tri: string;
  order: string;
  participants: User[] = [];
  redirectTo: string;
  user: User = new User();
  submitted: boolean = false;
  goToCongress: number;

  constructor(private participantService: ParticipantService) { }

  ngOnInit(): void {
    const that = this;
    this.dtOptions = {
      dom: "['<l>', Bfrtip']",
      buttons: [
        'colvis'
      ],
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      aLengthMenu: [[10, 25, 50, 100000], [10, 25, 50, "All"]],
      ajax: (dataTablesParameters: any, callback) => {
        const page = (dataTablesParameters.start / 10 + 1);
        const perPage = dataTablesParameters.length;
        const search = dataTablesParameters.search.value;
        const tri = dataTablesParameters.columns[dataTablesParameters.order[0].column].data;
        const order = dataTablesParameters.order[0].dir;
  
        that.busy = that.participantService.getParticipantsPagination(that.congressId, search, page, perPage, tri, order)
          .subscribe(
            (data: any) => {
              this.participants= data.data;
              callback({
                recordsTotal: data.to,
                recordsFiltered: data.total,
                data: that.participants
              });
            },
            (error) => {
            }
          );
      },
      columns: that.getColumns(),
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        jQuery('.logins-as-btn', row).unbind('click');
        jQuery('.logins-as-btn', row).bind('click', (event) => {
          this.showLoginModal(data);
        });
      }
    };
  }


  getColumns() {
    const columns = [
      {
        data: 'user_id',
        visible: true ,
      },
      {
        data: 'first_name',
        visible: true ,
        render: function (data, type, row) {
          return row.first_name + ' ' + row.last_name
        }
      },
      {
        data: 'email',
        visible: true ,
      },
      {
        data: 'mobile',
        visible: true ,
        render: function (data, type, row) {
          return row.mobile ? row.mobile : '-'
        }
      },
      {
        data: 'country_id',
        visible: true ,
        render: function (data, type, row) {
          return row.name ? row.name : '-'
        }
      },
      {
        data: 'loginAs',
        visible: true ,
        render: function (data, type, row) {
          return '<a href="javascript:void(0)" class="badge bg-info logins-as-btn"><span class="icon-arrow-right14"></span></a>';
        }
      },
      
    ];
    return columns;
  }

  showLoginModal(data) {
    this.user = data;
    jQuery('#continueModal').modal('show');
  }

  loginAs() {
    this.submitted = true;    
    if (!this.redirectTo) {
      Utils.sweetAlert('error', 'Veuillez choisir un projet', 'Erreur');
      return;
    }
    if ((this.redirectTo == 'pwa' || this.redirectTo == 'frontoffice') && !this.goToCongress) {
      Utils.sweetAlert('error', 'Ajoutez l\'Id de l\'événement', 'Erreur');
      return;
    }
    let link = this.redirectTo == 'frontoffice' ? environment.baseUrlFrontoffice + '/room/' + this.goToCongress + '/?token=' + this.user.qr_code + '&email=' + this.user.email : environment.baseUrlPWA + '/auth/login?token=' + this.user.qr_code + '&user=' + this.user.email + '&congressId=' + this.goToCongress;
    window.open(link, "_blank");
    jQuery('#continueModal').modal('hide');
    this.submitted = false;
  }

}
