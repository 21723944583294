<div class="upload-container">
    <input type="file" (change)="onFileChange($event)" accept=".xls,.xlsx" />
    
    <div *ngIf="selectedFile">
      <h3>Fichier sélectionné : {{ selectedFile.name }}</h3>
      <button (click)="deleteFile()">Supprimer</button>
      <button (click)="uploadFile()">Uploader</button>
    </div>
  
    <div *ngIf="excelData.length > 0">
      <h4>Aperçu du fichier Excel :</h4>
      <table>
        <tr *ngFor="let row of excelData">
          <td *ngFor="let cell of row">{{ cell }}</td>
        </tr>
      </table>
    </div>
  </div>
  