<div class="content">
  <div class="card">
    <div class="card-header header-elements-inline" [ngBusy]="busy">
      <h6 class="card-title">Liste des clients</h6>

      <div class="heading-elements">
        <button
          [routerLink]="['/admins/add-client']"
          type="button"
          class="btn btn-info btn-xs"
        >
          <i class="icon-plus2 position-left"></i>Ajouter un client
        </button>
        <button type="button" class="btn btn-info btn-xs" data-toggle="modal" data-target="#bulkEmailModal">
          <i class="icon-envelope position-left"></i>Envoi en masse
        </button>
      </div>
    </div>
    <br />

    <div class="card-body">
      <table class="table perso-table" datatable [dtOptions]="dtOptions">
        <thead>
          <tr>
            <th>Nom de l'Entreprise</th>
            <th>Nom du dirigeant</th>
            <!--th>Salarié qui gère le client</th-->
            <th>Téléphone</th>
            <th>Email</th>
            <th>Adresse</th>
            <th>Secteur d'activité</th>
            <th>Siret</th>
            <!--th>IDCC</th>
            <th>Code NAF</th>
            <th>Date de création</th>
            <th>URSSAF Identifiant</th>
            <th>IMPOTS Identifiant</th>
            <th>RETRAITE Identifiant</th>
            <th>PREVOYANCE Identifiant</th>
            <th>MUTUELLE Identifiant</th-->
            <th>Actions</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
  </div>
</div>
<!-- Email Modal -->
<div class="modal fade" id="emailModal" tabindex="-1" role="dialog" aria-labelledby="emailModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="emailModalLabel">Sélectionnez les Destinataires</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label for="recipient-emails">Emails</label>
          <ul class="list-group">
            <li *ngFor="let email of emailList" class="list-group-item">
              <input 
                type="checkbox" 
                class="checkboxmail"
                [checked]="selectedEmails.includes(email)" 
                (change)="toggleEmailSelection(email, $event.target.checked)" />
              {{ email }}
            </li>
          </ul>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" [disabled]="isSending">Fermer</button>
        <button type="button" class="btn btn-primary" (click)="sendSelectedEmails()"  [disabled]="isSending">Envoyer</button>
      </div>
    </div>
  </div>
</div>

<!-- Fenêtre modale pour la sélection des utilisateurs et des modèles d'emails -->
<div class="modal fade" id="bulkEmailModal" tabindex="-1" role="dialog" aria-labelledby="bulkEmailModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="bulkEmailModalLabel">Envoyer des emails en masse</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <!-- Colonne gauche : Liste des modèles d'emails -->
          <div class="col-md-6">
            <h6>Modèles d'emails</h6>
            <!-- Case à cocher pour sélectionner tous les modèles d'emails -->
            <div class="form-check">
              <input 
                type="checkbox" 
                class="form-check-input" 
                id="selectAllEmails" 
                (change)="toggleSelectAllEmails($event.target.checked)" />
              <label class="form-check-label" for="selectAllEmails">
                Sélectionner/Désélectionner tous les modèles d'emails
              </label>
            </div>
            <ul class="list-group">
              <li *ngFor="let email of emailList" class="list-group-item">
                <input 
                  type="checkbox" 
                  class="checkboxmail"
                  [checked]="selectedEmails.includes(email)" 
                  (change)="toggleEmailSelection(email, $event.target.checked)" />
                {{ email }}
              </li>
            </ul>
          </div>

          <!-- Colonne droite : Liste des utilisateurs -->
          <div class="col-md-6">
            <h6>Utilisateurs</h6>
            <!-- Case à cocher pour sélectionner tous les utilisateurs -->
            <div class="form-check">
              <input 
                type="checkbox" 
                class="form-check-input" 
                id="selectAllClients" 
                (change)="toggleSelectAllClients($event.target.checked)" />
              <label class="form-check-label" for="selectAllClients">
                Sélectionner/Désélectionner tous les utilisateurs
              </label>
            </div>
            <ul class="list-group">
              <li *ngFor="let client of clients" class="list-group-item">
                <input 
                  type="checkbox" 
                  class="checkboxmailuser" 
                  [checked]="selectedClients.includes(client.email)" 
                  (change)="toggleClientSelection(client.email, $event.target.checked)" />
                {{ client.company_name }} ({{ client.email }})
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" [disabled]="isSendingMasse">Fermer</button>
        <button type="button" class="btn btn-primary" (click)="sendSelectedEmailsMasse()" [disabled]="isSendingMasse">Envoyer</button>
      </div>
    </div>
  </div>
</div>

