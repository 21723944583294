<!-- Form horizontal -->
<div class="card">
  <div class="card-header header-elements-inline">
    <h5 class="card-title">{{ !isEditMode ? 'Ajout Client' : 'Modifier Client' }}</h5>
  </div>
  <div class="card-body">
    <form class="form-horizontal" action="#" [ngBusy]="busy">
      <!-- Remarques -->
      <div class="row">
        <div class="form-group col-md-12">
          <label class="control-label">Remarques:</label>
          <textarea [(ngModel)]="client.remarques" name="remarques" class="form-control" rows="4"></textarea>
        </div>
      </div>
      <!-- First Row: Company Name, Director Name, Phone -->
      <div class="row">
        <div class="form-group col-md-4">
          <label class="control-label">Nom de l’Entreprise:</label>
          <input [(ngModel)]="client.company_name" name="company_name" type="text" class="form-control">
          <label *ngIf="submitted && !client.company_name" class="form-text text-danger">required</label>
        </div>

        <div class="form-group col-md-4">
          <label class="control-label">Nom et prénom du dirigeant:</label>
          <input [(ngModel)]="client.director_name" name="director_name" type="text" class="form-control">
          <label *ngIf="submitted && !client.director_name" class="form-text text-danger">required</label>
        </div>

        <div class="form-group col-md-4">
          <label class="control-label">Téléphone:</label>
          <input [(ngModel)]="client.phone" (ngModelChange)="formatPhone($event)" name="phone" type="text" class="form-control">
          <label *ngIf="submitted && !client.phone" class="form-text text-danger">required</label>
        </div>
      </div>

      <!-- Second Row: Email, Address, Activity Sector -->
      <div class="row">
        <div class="form-group col-md-4">
          <label class="control-label">Email:</label>
          <input [(ngModel)]="client.email" name="email" (ngModelChange)="client.email = client.email.toLowerCase()" type="email" class="form-control">
          <label *ngIf="submitted && !client.email" class="form-text text-danger">required</label>
        </div>

        <div class="form-group col-md-4">
          <label class="control-label">Adresse:</label>
          <input [(ngModel)]="client.address" name="address" type="text" class="form-control">
          <label *ngIf="submitted && !client.address" class="form-text text-danger">required</label>
        </div>

        <div class="form-group col-md-4">
          <label class="control-label">Secteur d'activité:</label>
          <select [(ngModel)]="client.activity_sector" name="activity_sector" class="form-control">
            <option *ngFor="let sector of sectors" [value]="sector">{{ sector }}</option>
          </select>
          <label *ngIf="submitted && !client.activity_sector" class="form-text text-danger">required</label>
        </div>
      </div>
      

      <!-- Third Row: Siret, IDCC, Code NAF -->
      <div class="row">
        <div class="form-group col-md-4">
          <label class="control-label">Siret:</label>
          <input [(ngModel)]="client.siret" name="siret" type="text" class="form-control">
          <label *ngIf="submitted && !client.siret" class="form-text text-danger">required</label>
        </div>

        <div class="form-group col-md-4">
          <label class="control-label">IDCC:</label>
          <input [(ngModel)]="client.idcc" name="idcc" type="text" class="form-control">
          <label *ngIf="submitted && !client.idcc" class="form-text text-danger">required</label>
        </div>

        <div class="form-group col-md-4">
          <label class="control-label">Code NAF:</label>
          <input [(ngModel)]="client.code_naf" name="code_naf" type="text" class="form-control">
          <label *ngIf="submitted && !client.code_naf" class="form-text text-danger">required</label>
        </div>
        </div>
        <!-- Row: CNI  -->
        <div class="row">
          <div class="col-md-12">
            <div class="form-group " *ngIf="ready || !isEditMode">
              <label class="control-label">CNI :</label>
              <div class="file-loading">
                <app-upload-file [maxFileCount]="10" [uploadType]="'file-input'" [previewFiles]="pathsCNI"
                  (onUpload)="onUploadImageCni($event)" (onDelete)="onDeleteImageCni($event)"
                  [accept]="'image/*,application/pdf'">
                </app-upload-file>
              </div>
            </div>
          </div>
        </div>
      <!-- Row: Kbis -->
      <div class="row">
        <div class="col-md-12">
          <div class="form-group " *ngIf="ready || !isEditMode">
            <label class="control-label">Kbis:</label>
            <div class="file-loading">
              <app-upload-file [maxFileCount]="10" [uploadType]="'file-input'" [previewFiles]="pathsBanner"
                (onUpload)="onUploadImage($event)" (onDelete)="onDeleteImage($event)"
                [accept]="'image/*,application/pdf'">
              </app-upload-file>
            </div>
          </div>
        </div>
      </div>
      <!-- Fourth Row: Creation Date, Manager IDs -->
      <div class="row">
        <!--div class="form-group col-md-6">
          <label class="control-label">Date de création:</label>
          <input [(ngModel)]="client.creation_date" name="creation_date" type="date" class="form-control">
          <label *ngIf="submitted && !client.creation_date" class="form-text text-danger">required</label>
        </div-->

        <div class="form-group col-md-12">
          <label class="control-label">Salarié qui gère le client:</label>
          <select multiple [(ngModel)]="client.manager_ids" name="manager_ids" class="form-control">
            <option *ngFor="let admin of admins" [value]="admin.admin_id">{{ admin.name }}</option>
          </select>
          <label *ngIf="submitted && !client.manager_ids" class="form-text text-danger">required</label>
        </div>
      </div>
      <!-- NET Entreprise-->
      <div class="net-entreprise-section">
        <h3>NET Entreprise</h3>
        <div class="row">
          <div class="form-group col-md-6">
            <label for="netSiret">Siret</label>
            <input type="text" id="netSiret" [(ngModel)]="client.net_siret" name="net_siret" class="form-control">
          </div>

          <div class="form-group col-md-6">
            <label for="netNom">Nom</label>
            <input type="text" id="netNom" [(ngModel)]="client.net_nom" name="net_nom" class="form-control">
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6">
            <label for="netPrenom">Prénom</label>
            <input type="text" id="netPrenom" value="client.net_prenom" [(ngModel)]="client.net_prenom" name="net_prenom" class="form-control">
          </div>
          <div class="form-group col-md-6">
            <label class="control-label">Mot de passe:</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <button type="button" class="btn" (click)="toggleNETPasswordVisibility()"
                  style="border:none;background:none;">
                  <i [ngClass]="showNETPassword ? 'icon-eye-slash' : 'icon-eye'"></i>
                </button>
              </div>
              <input [(ngModel)]="client.net_password" [(value)]="client.net_password" name="net_password" type="password"
                [type]="showNETPassword ? 'text' : 'password'" class="form-control" style="padding-left: 40px;">
            </div>
          </div>

        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group " *ngIf="ready || !isEditMode">
              <label class="control-label">NET Entreprise Upload:</label>
              <div class="file-loading">
                <app-upload-file [maxFileCount]="10" [uploadType]="'file-input'" [previewFiles]="pathsNet"
                  (onUpload)="onUploadImageNet($event)" (onDelete)="onDeleteImageNet($event)"
                  [accept]="'image/*,application/pdf'">
                </app-upload-file>
              </div>
            </div>
          </div>
        </div>
      </div>
<!-- URSSAF Identifiants and Password -->
      <div class="urssaf-section">
        <h3>URSSAF</h3>
      
      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label">URSSAF Identifiant:</label>
          <input [(ngModel)]="client.urssaf_identifiant" [(value)]="client.urssaf_identifiant" name="urssaf_identifiant" id="urssaf_identifiant" type="text" class="form-control">
        </div>
        <div class="form-group col-md-6">
          <label class="control-label">URSSAF Mot de passe:</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <button type="button" class="btn" (click)="toggleUrssafPasswordVisibility()"
                style="border:none;background:none;">
                <i [ngClass]="showUrssafPassword ? 'icon-eye-slash' : 'icon-eye'"></i>
              </button>
            </div>
            <input [(ngModel)]="client.urssaf_password" name="urssaf_password" type="password"
              [type]="showUrssafPassword ? 'text' : 'password'" class="form-control" style="padding-left: 40px;">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group " *ngIf="ready || !isEditMode">
            <label class="control-label">URSSAF Upload:</label>
            <div class="file-loading">
              <app-upload-file [maxFileCount]="10" [uploadType]="'file-input'" [previewFiles]="pathsUrssaf"
                (onUpload)="onUploadImageUrssaf($event)" (onDelete)="onDeleteImageUrssaf($event)"
                [accept]="'image/*,application/pdf'">
              </app-upload-file>
            </div>
          </div>
        </div>
      </div>
    </div>

      <!-- IMPOTS Identifiants and Password -->
      <div class="impots-section">
        <h3>IMPOTS</h3>
        <div class="row">
          <div class="form-group col-md-6">
            <label class="control-label">IMPOTS Identifiant:</label>
            <input [(ngModel)]="client.impots_identifiant" name="impots_identifiant" type="text" class="form-control">
          </div>
          <div class="form-group col-md-6">
            <label class="control-label">IMPOTS Mot de passe:</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <button type="button" class="btn" (click)="toggleImpotsPasswordVisibility()"
                  style="border:none;background:none;">
                  <i [ngClass]="showImpotsPassword ? 'icon-eye-slash' : 'icon-eye'"></i>
                </button>
              </div>
              <input [(ngModel)]="client.impots_password" name="impots_password" type="password"
                [type]="showImpotsPassword ? 'text' : 'password'" class="form-control" style="padding-left: 40px;">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group " *ngIf="ready || !isEditMode">
              <label class="control-label">IMPOTS Upload:</label>
              <div class="file-loading">
                <app-upload-file [maxFileCount]="10" [uploadType]="'file-input'" [previewFiles]="pathsImpots"
                  (onUpload)="onUploadImageImpots($event)" (onDelete)="onDeleteImageImpots($event)"
                  [accept]="'image/*,application/pdf'">
                </app-upload-file>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- RETRAITE Identifiants and Password -->
      <div class="retraite-section">
        <h3>RETRAITE</h3>
        <div class="row">
          <div class="form-group col-md-6">
            <label class="control-label">RETRAITE Identifiant:</label>
            <input [(ngModel)]="client.retraite_identifiant" name="retraite_identifiant" type="text" class="form-control">
          </div>
          <div class="form-group col-md-6">
            <label class="control-label">RETRAITE Mot de passe:</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <button type="button" class="btn" (click)="toggleRetraitePasswordVisibility()"
                  style="border:none;background:none;">
                  <i [ngClass]="showRetraitePassword ? 'icon-eye-slash' : 'icon-eye'"></i>
                </button>
              </div>
              <input [(ngModel)]="client.retraite_password" name="retraite_password" type="password"
                [type]="showRetraitePassword ? 'text' : 'password'" class="form-control" style="padding-left: 40px;">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group " *ngIf="ready || !isEditMode">
              <label class="control-label">RETRAITE Upload:</label>
              <div class="file-loading">
                <app-upload-file [maxFileCount]="10" [uploadType]="'file-input'" [previewFiles]="pathsRetraite"
                  (onUpload)="onUploadImageRetraite($event)" (onDelete)="onDeleteImageRetraite($event)"
                  [accept]="'image/*,application/pdf'">
                </app-upload-file>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- PREVOYANCE Identifiants and Password -->
      <div class="prevoyance-section">
        <h3>PREVOYANCE</h3>
        <div class="row">
          <div class="form-group col-md-6">
            <label class="control-label">PREVOYANCE Identifiant:</label>
            <input [(ngModel)]="client.prevoyance_identifiant" name="prevoyance_identifiant" type="text" class="form-control">
          </div>
          <div class="form-group col-md-6">
            <label class="control-label">PREVOYANCE Mot de passe:</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <button type="button" class="btn" (click)="togglePrevoyancePasswordVisibility()"
                  style="border:none;background:none;">
                  <i [ngClass]="showPrevoyancePassword ? 'icon-eye-slash' : 'icon-eye'"></i>
                </button>
              </div>
              <input [(ngModel)]="client.prevoyance_password" name="prevoyance_password" type="password"
                [type]="showPrevoyancePassword ? 'text' : 'password'" class="form-control" style="padding-left: 40px;">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group " *ngIf="ready || !isEditMode">
              <label class="control-label">PREVOYANCE Upload:</label>
              <div class="file-loading">
                <app-upload-file [maxFileCount]="10" [uploadType]="'file-input'" [previewFiles]="pathsPrevoyance"
                  (onUpload)="onUploadImagePrevoyance($event)" (onDelete)="onDeleteImagePrevoyance($event)"
                  [accept]="'image/*,application/pdf'">
                </app-upload-file>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- MUTUELLE Identifiants and Password -->
      <div class="mutuelle-section">
        <h3>MUTUELLE</h3>
        <div class="row">
          <div class="form-group col-md-6">
            <label class="control-label">MUTUELLE Identifiant:</label>
            <input [(ngModel)]="client.mutuelle_identifiant" name="mutuelle_identifiant" type="text" class="form-control">
          </div>
          <div class="form-group col-md-6">
            <label class="control-label">MUTUELLE Mot de passe:</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <button type="button" class="btn" (click)="toggleMutuellePasswordVisibility()"
                  style="border:none;background:none;">
                  <i [ngClass]="showMutuellePassword ? 'icon-eye-slash' : 'icon-eye'"></i>
                </button>
              </div>
              <input [(ngModel)]="client.mutuelle_password" name="mutuelle_password" type="password"
                [type]="showMutuellePassword ? 'text' : 'password'" class="form-control" style="padding-left: 40px;">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group " *ngIf="ready || !isEditMode">
              <label class="control-label">MUTUELLE Upload:</label>
              <div class="file-loading">
                <app-upload-file [maxFileCount]="10" [uploadType]="'file-input'" [previewFiles]="pathsMutuelle"
                  (onUpload)="onUploadImageMutuelle($event)" (onDelete)="onDeleteImageMutuelle($event)"
                  [accept]="'image/*,application/pdf'">
                </app-upload-file>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Congés Payés Identifiants and Password -->
      <div class="cp-section">
        <h3>Dext</h3>
        <div class="row">
          <div class="form-group col-md-6">
            <label for="cpLogin">Dext Login</label>
            <input type="text" id="cpLogin" [(ngModel)]="client.cp_login" name="cp_login" class="form-control">
          </div>
      
          <div class="form-group col-md-6">
            <label class="control-label">Dext Mot de passe :</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <button type="button" class="btn" (click)="toggleCongesPasswordVisibility()"
                  style="border:none;background:none;">
                  <i [ngClass]="showCongesPassword ? 'icon-eye-slash' : 'icon-eye'"></i>
                </button>
              </div>
              <input [(ngModel)]="client.cp_password" name="conges_password" type="password"
                [type]="showCongesPassword ? 'text' : 'password'" class="form-control" style="padding-left: 40px;">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group " *ngIf="ready || !isEditMode">
              <label class="control-label">LAB (Lutte anti-blanchiment) / Fiche signalétique :</label>
              <div class="file-loading">
                <app-upload-file [maxFileCount]="10" [uploadType]="'file-input'" [previewFiles]="pathsCp"
                  (onUpload)="onUploadImageCp($event)" (onDelete)="onDeleteImageCp($event)"
                  [accept]="'image/*,application/pdf'">
                </app-upload-file>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- CIBTP Identifiants and Password -->
      <div class="cibtp-section">
        <h3>CIBTP</h3>
        <div class="row">
          <div class="form-group col-md-6">
            <label for="cibtpLogin">CIBTP Login</label>
            <input type="text" id="cibtpLogin" [(ngModel)]="client.cibtp_login" name="cibtp_login" class="form-control">
          </div>
      
          <div class="form-group col-md-6">
            <label class="control-label">CIBTP Mot de passe:</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <button type="button" class="btn" (click)="toggleCIBTPPasswordVisibility()"
                  style="border:none;background:none;">
                  <i [ngClass]="showCIBTPPassword ? 'icon-eye-slash' : 'icon-eye'"></i>
                </button>
              </div>
              <input [(ngModel)]="client.cibtp_password" name="cibtp_password" type="password"
                [type]="showCIBTPPassword ? 'text' : 'password'" class="form-control" style="padding-left: 40px;">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group " *ngIf="ready || !isEditMode">
              <label class="control-label">CIBTP Upload:</label>
              <div class="file-loading">
                <app-upload-file [maxFileCount]="10" [uploadType]="'file-input'" [previewFiles]="pathsCibtp"
                  (onUpload)="onUploadImageCibtp($event)" (onDelete)="onDeleteImageCibtp($event)"
                  [accept]="'image/*,application/pdf'">
                </app-upload-file>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group " *ngIf="ready || !isEditMode">
              <label class="control-label">Dossier Bilan:</label>
              <div class="file-loading">
                <app-upload-file [maxFileCount]="10" [uploadType]="'file-input'" [previewFiles]="pathsBilan"
                 (onUpload)="onUploadImageBilan($event)" (onDelete)="onDeleteImageBilan($event)"
                 [accept]="'image/*,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xls,.xlsx'">
                </app-upload-file>

              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Submit Button -->
      <div class="row text-center">
        <button type="submit" (click)="submitClient()" class="btn btn-primary">
          {{ !isEditMode ? 'Ajouter Client' : 'Enregistrer les modifications' }}
          <i class="icon-arrow-right14 position-right"></i>
        </button>
      </div>

    </form>
  </div>
</div>

