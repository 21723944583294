import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AdminService } from '../../services/admin.service';
import { Utils } from '../../utils/utils';
declare const jQuery: any;

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css']
})
export class UploadFileComponent implements OnInit {

  @Input()
  maxFileCount?: number;


  @Input()
  previewFiles: string[] = [];

  @Input()
  url? = environment.baseUrlApi + '/files/upload-resource';

  @Input()
  uploadType : string;

  @Input()
  path = null;

  @Input()
  accept = null;

  @Output()
  onUpload: EventEmitter<any> = new EventEmitter();

  @Output()
  onDelete: EventEmitter<any> = new EventEmitter();

  @Output()
  onPhotoUploaded: EventEmitter<any> = new EventEmitter();
  className: string;
  uploadCrop: any;

  constructor(private adminService: AdminService) {
    this.className = Utils.getRandomString();
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    if (this.uploadType === 'file-input') {
      this.initUploadFile();
    } else if (this.uploadType === 'normal') {
      this.initFileInput();
    }    
  }

  initUploadFile() {
    const baseContext = this;
    let index = 0 ;
    Utils.initializeUploadFile(this.url, this.adminService.getToken(), "." + this.className,
      this.maxFileCount, this.previewFiles, Utils.getInitialPreviewConfig(this.previewFiles));
    jQuery('.' + baseContext.className).change(function () {
    }).on('fileuploaded', function (event, data) {
      let files = jQuery('.' + baseContext.className)[0].files;
      let file  = jQuery('.' + baseContext.className)[0].files[index];
      if (file) {
        data.response.fileName = file.name;
      }
      baseContext.onUpload.emit(data.response);  
      index++;
      if (files.length == index) {
        index = 0;
      }
    }).on('filedeleted', function (event, key, jqXHR, data) {
      baseContext.onDelete.emit(jqXHR);
    });
  }

  detroyUploadFile() {
    const baseContext = this;
    jQuery('.' + baseContext.className).fileinput('destroy');
  }

  initFileInput() {
    Utils.initFileInput(this.className);
  }
  
  setPhoto(event, path = null) {
    if (this.uploadType === 'croppie') {
      let file = null;
    if (this.uploadCrop !== undefined) {
      this.uploadCrop.croppie('destroy');
    }
    if (event !== null) {
      file = event.target.files[0]
    }
    this.uploadCrop = Utils.initCroppie('upload-demo', file, path);
    if (!path) {
      this.onPhotoUploaded.emit(true);
    }
    } else if (this.uploadType === 'normal') {
      this.onPhotoUploaded.emit(event.target.files[0]);
    }
    
  }
}
