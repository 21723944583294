import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Utils} from "../.././utils/utils";

declare var jQuery: any;

@Component({
  selector: 'app-ng-checkbox',
  templateUrl: './ng-checkbox.component.html',
  styleUrls: ['./ng-checkbox.component.css']
})
export class NgCheckboxComponent implements OnInit, AfterViewInit {

  className: string;

  @Input()
  basicStyle?: boolean;

  @Input()
  label: string;

  @Output()
  selectionChange: EventEmitter<any>;

  selected: any;

  constructor() {
    this.className = Utils.getRandomString();
    this.selectionChange = new EventEmitter<any>();
  }

  ngOnInit() {
  }

  @Input()
  get selection() {
    return this.selected;
  }

  set selection(value) {
    Utils.setCheckBoxValue(this.className, value);
    this.selected = value;
  }

  ngAfterViewInit(): void {
    const baseContext = this;
    Utils.initCheckBox(this.className);
    jQuery('.' + baseContext.className).on('change', function () {
      baseContext.selectionChange.emit(baseContext.selected ? 0 : 1);
    });
  }

}
