import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { AddProjectComponent } from './add-project/add-project.component';
import { ManageProjectsComponent } from './manage-projects.component';


export const routes: Routes = [
    {
        path: 'list',
        component: ManageProjectsComponent,
       
    },
    {
        path: 'add',
        component: AddProjectComponent,
    },
    {
        path: 'edit/:project_id',
        component: AddProjectComponent,
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ManageProjectsRoutingModule {
}
