<!-- Content area -->
<div class="content">
    <!-- Invoice archive -->
    <div class="card" style="margin-top:50px">

        <div class="card-header header-elements-inline" [ngBusy]="busy">

        </div>
        <div class="card-body">
          <!--           
          <div class="congress-filter mb-4">
            <app-ng-select2 [(selection)]="congressId" [defaultValue]="'Choisir un evenement'"
            [objects]="congress" [stringOption]="'name'" [idOption]="'congress_id'"  (selectionChange)="onSelectCongresssChange($event)">
          </app-ng-select2>
          </div> -->
            <table id="participantsTable" cellspacing="0" width="100%" datatable [dtOptions]="dtOptions"
                   class="table participants-table list-participants-table">
              <thead>
              <tr>
                <th>UserId</th>
                <th>Nom</th>
                <th>Email</th>
                <th>Téléphone</th>
                 <th>Pays</th>
                <th>Login As</th> 
              </tr>
              </thead>
              <tbody>
              </tbody>
            </table>
          </div>
        </div>
        <!-- /invoice archive -->
      </div>



<div class="modal fade" id="continueModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Continuer vers</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-6 ml-3">
            <label class="control-label font-16">Frontoffice</label>
            <input name="options" ng-control="options" class="ml-1" type="radio" value="frontoffice"
              [(ngModel)]="redirectTo" />
            <br>
            <label class="control-label font-16">PWA</label>
            <input name="options" ng-control="options" class="ml-1" type="radio" value="pwa" [(ngModel)]="redirectTo" />
            <div>
              <label class="control-label font-16">L'Id de l'événement</label>
              <input [(ngModel)]="goToCongress" name="nom" type="text" class="form-control">
              <label *ngIf="submitted && (redirectTo=='pwa'|| redirectTo=='frontoffice') && !goToCongress" class="form-text text-danger">required</label>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="loginAs()">Continuer</button>
      </div>
    </div>
  </div>
</div>
