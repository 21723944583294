import {Utils} from './utils';

declare let d3: any;
declare let jQuery: any;
declare let echarts: any;

export class ChartUtils {

    static initBarChart(element, data, height, animate, easing, duration, delay, color, tooltip) {
        if (typeof d3 == 'undefined') {
            console.warn('Warning - d3.min.js is not loaded.');
            return;
        }

        // Initialize chart only if element exsists in the DOM
        if (jQuery(element).length > 0) {
            // Basic setup
            // ------------------------------

            // Add data set
            var bardata = data;

            // Main variables
            var d3Container = d3.select(element),
                width = d3Container.node().getBoundingClientRect().width;


            // Construct scales
            // ------------------------------

            // Horizontal
            var x = d3.scale.ordinal()
                .rangeBands([0, width], 0.3);

            // Vertical
            var y = d3.scale.linear()
                .range([0, height]);


            // Set input domains
            // ------------------------------

            // Horizontal
            x.domain(d3.range(0, bardata.length));

            // Vertical
            y.domain([0, d3.max(bardata)]);


            // Create chart
            // ------------------------------

            // Add svg element
            var container = d3Container.append('svg');

            // Add SVG group
            var svg = container
                .attr('width', width)
                .attr('height', height)
                .append('g');


            //
            // Append chart elements
            //

            // Bars
            var bars = svg.selectAll('rect')
                .data(bardata)
                .enter()
                .append('rect')
                .attr('class', 'd3-random-bars')
                .attr('width', x.rangeBand())
                .attr('x', function (d, i) {
                    return x(i);
                })
                .style('fill', color);


            // Tooltip
            // ------------------------------

            var tip = d3.tip()
                .attr('class', 'd3-tip')
                .offset([-10, 0]);

            // Show and hide
            bars.call(tip)
                .on('mouseover', tip.show)
                .on('mouseout', tip.hide);


            /*  // Daily meetings tooltip content
              if (tooltip == 'hours') {
                  tip.html(function (d, i) {
                      return '<div class="text-center">' +
                          '<h6 class="m-0">' + d + '</h6>' +
                          '<span class="font-size-sm">meetings</span>' +
                          '<div class="font-size-sm">' + i + ':00' + '</div>' +
                          '</div>';
                  });
              }

              // Statements tooltip content
              if (tooltip == 'goal') {
                  tip.html(function (d, i) {
                      return '<div class="text-center">' +
                          '<h6 class="m-0">' + d + '</h6>' +
                          '<span class="font-size-sm">statements</span>' +
                          '<div class="font-size-sm">' + i + ':00' + '</div>' +
                          '</div>';
                  });
              }*/

            // Online members tooltip content
            tip.html(function (d, i) {
                return '<div class="text-center">' +
                    '<h6 class="m-0">' + Utils.convertToBigValue(d, 2) + '</h6>' +
                    '<span class="font-size-sm">' + tooltip + '</span>' +
                    '</div>';
            });


            // Animate on load
            var _withAnimation = function () {
                bars
                    .attr('height', 0)
                    .attr('y', height)
                    .transition()
                    .attr('height', function (d) {
                        return y(d);
                    })
                    .attr('y', function (d) {
                        return height - y(d);
                    })
                    .delay(function (d, i) {
                        return i * delay;
                    })
                    .duration(duration)
                    .ease(easing);
            };

            // Load without animateion
            var _withoutAnimation = function () {
                bars
                    .attr('height', function (d) {
                        return y(d);
                    })
                    .attr('y', function (d) {
                        return height - y(d);
                    });
            };
            // Bar loading animation
            // ------------------------------

            // Choose between animated or static
            if (animate) {
                _withAnimation();
            } else {
                _withoutAnimation();
            }


            // Resize function
            // Since D3 doesn't support SVG resize by default,
            // we need to manually specify parts of the graph that need to
            // be updated on window resize
            var _barsResize = function () {

                // Layout variables
                width = d3Container.node().getBoundingClientRect().width;


                // Layout
                // -------------------------

                // Main svg width
                container.attr('width', width);

                // Width of appended group
                svg.attr('width', width);

                // Horizontal range
                x.rangeBands([0, width], 0.3);


                // Chart elements
                // -------------------------

                // Bars
                svg.selectAll('.d3-random-bars')
                    .attr('width', x.rangeBand())
                    .attr('x', function (d, i) {
                        return x(i);
                    });
            };


            // Resize chart
            // ------------------------------
            _barsResize();
            // Call function on window resize

            // Call function on sidebar width change

            // On sidebar width change
            jQuery(document).on('click', '.sidebar-control', function () {
                setTimeout(function () {
                    _barsResize();
                }, 0);
            });

            //TODO Fixing this
            // jQuery(document).on('click', '.sidebar-control', _barsResize());


        }

        // On window resize
        console.log('Enter her !!!');
        var resizeCharts;
        window.onresize = function () {
            console.log('resize chart bar');
            clearTimeout(resizeCharts);
            resizeCharts = setTimeout(function () {
                _barsResize();
            }, 200);
        };
    }


    static initEchartArea() {

        let area_basic_element = document.getElementById('area_basic');

        // Initialize chart
        var area_basic = echarts.init(area_basic_element);


        //
        // Chart config
        //

        // Options
        area_basic.setOption({

            // Define colors
            color: ['#b6a2de', '#2ec7c9'],

            // Global text styles
            textStyle: {
                fontFamily: 'Roboto, Arial, Verdana, sans-serif',
                fontSize: 13
            },

            // Chart animation duration
            animationDuration: 750,

            // Setup grid
            grid: {
                left: 0,
                right: 40,
                top: 35,
                bottom: 0,
                containLabel: true
            },

            // Add legend
            legend: {
                data: ['Products Sold', 'Total Views'],
                itemHeight: 8,
                itemGap: 20
            },

            // Add tooltip
            tooltip: {
                trigger: 'axis',
                backgroundColor: 'rgba(0,0,0,0.75)',
                padding: [10, 15],
                textStyle: {
                    fontSize: 13,
                    fontFamily: 'Roboto, sans-serif'
                }
            },

            // Horizontal axis
            xAxis: [{
                type: 'category',
                boundaryGap: false,
                data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June'],
                axisLabel: {
                    color: '#333'
                },
                axisLine: {
                    lineStyle: {
                        color: '#999'
                    }
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#eee',
                        type: 'dashed'
                    }
                }
            }],

            // Vertical axis
            yAxis: [{
                type: 'value',
                axisLabel: {
                    color: '#333'
                },
                axisLine: {
                    lineStyle: {
                        color: '#999'
                    }
                },
                splitLine: {
                    lineStyle: {
                        color: '#eee'
                    }
                },
                splitArea: {
                    show: true,
                    areaStyle: {
                        color: ['rgba(0,0,0,0.01)', 'rgba(250,250,250,0.1)']
                    }
                }
            }],

            // Add series
            series: [
                {
                    name: 'Products Sold',
                    type: 'line',
                    data: [10, 12, 21, 12, 20, 18],
                    areaStyle: {
                        normal: {
                            opacity: 0.25
                        }
                    },
                    smooth: true,
                    symbolSize: 7,
                    itemStyle: {
                        normal: {
                            borderWidth: 2
                        }
                    }
                },
                {
                    name: 'Total Views',
                    type: 'line',
                    smooth: true,
                    symbolSize: 7,
                    itemStyle: {
                        normal: {
                            borderWidth: 2
                        }
                    },
                    areaStyle: {
                        normal: {
                            opacity: 0.25
                        }
                    },
                    data: [20, 8, 15, 6, 10, 10]
                }
            ]
        });
        //
        // Resize charts
        //

        // Resize function
        var triggerChartResize = function () {
            area_basic_element && area_basic.resize();
        };

        // On sidebar width change
        jQuery(document).on('click', '.sidebar-control', function () {
            setTimeout(function () {
                triggerChartResize();
            }, 0);
        });

        // On window resize
        var resizeCharts;
        window.onresize = function () {
            console.log('resize area chart');
            clearTimeout(resizeCharts);
            resizeCharts = setTimeout(function () {
                triggerChartResize();
            }, 200);
        };
    }

}
