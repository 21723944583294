<!-- Content area -->
<div class="content">
  <!-- Invoice archive -->
  <div class="card">
    <div class="card-header header-elements-inline" [ngBusy]="busy">
      <h6 class="card-title">Liste des mails</h6>

      <div class="heading-elements">
        <button class="btn btn-primary" (click)="addMailType()">Ajouter un type de mail</button>
      </div>

    </div>
    <br>

    <div class="card-body">
      <table class="table mail-list-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Titre</th>
            <th>Objet</th>
            <th>Etat</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of mailTypes;let i=index;">
            <td>
              {{item.mail_admin_id}}
            </td>
            <td>
              {{item.titre_mail}}
            </td>
            <td>
              {{item.object}}
            </td>
            <td>
              <span class="bg badge label  {{mailTypes===0 ? 'bg-grey' : 'bg-success'}}">{{mailTypes.length===0 ? 'Pas
                encore': 'Configuré'}}</span>
            </td>
            <td>
              <div class="list-icons">
                <div class="dropdown">
                  <a href="#" class="list-icons-item" data-toggle="dropdown">
                    <i class="icon-menu9"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" [routerLink]="['/manage-mail/type/'+item.mail_admin_id]"><i
                        class="icon-database-edit2"></i>Configurer</a>
                    <!-- Bouton de suppression -->
                    <a class="dropdown-item" (click)="deleteMail(item.mail_admin_id)">
                      <i class="icon-trash"></i>Supprimer
                    </a>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- /invoice archive -->

</div>
<!-- /content area -->