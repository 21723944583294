import { Component } from '@angular/core';
import * as XLSX from 'xlsx';
import { ClientService } from 'src/app/shared/services/client.service';
import { Subscription } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-excel-upload',
  templateUrl: './excel-upload.component.html',
  styleUrls: ['./excel-upload.component.css']
})
export class ExcelUploadComponent {
  excelData: any[] = [];
  selectedFile: File | null = null;
  busy: Subscription;

  constructor(
    private clientService: ClientService
  ) {
    
  }

  // Méthode pour lire le fichier Excel
  onFileChange(event: any): void {
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      alert('Vous ne pouvez sélectionner qu\'un seul fichier');
      return;
    }

    this.selectedFile = target.files[0];

    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const binaryData: string = e.target.result;
      const workbook: XLSX.WorkBook = XLSX.read(binaryData, { type: 'binary' });

      const sheetName: string = workbook.SheetNames[0];
      const sheet: XLSX.WorkSheet = workbook.Sheets[sheetName];

      this.excelData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
    };
    reader.readAsBinaryString(this.selectedFile);
  }

  // Méthode pour supprimer le fichier sélectionné
  deleteFile(): void {
    this.selectedFile = null;
    this.excelData = [];
  }

  // Méthode pour uploader le fichier (ajouter votre logique d'upload ici)
  uploadFile(): void {
    console.log(this.selectedFile);
    if (this.selectedFile) {
      console.log(this.selectedFile.name);
      console.log(this.selectedFile);
      const formData = new FormData();
      formData.append('file', this.selectedFile, this.selectedFile.name);

      formData.forEach((value, key) => {
        console.log(`${key}:`, value);
      });
      
      console.log('FormData avant envoi:', formData);
      this.busy = this.clientService.uploadExcel(formData)
        .subscribe({
          next: (response: any) => {
            console.log('Réponse du serveur:', response);
            alert(response.message);
          },
          error: (error) => {
            console.error('Erreur lors de l\'upload:', error);
            alert('Erreur lors de l\'upload du fichier');
          }
        });
    } else {
      alert('Veuillez sélectionner un fichier');
    }
  
  }
}
