import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Menu, MenuChildren } from '../shared/models/Menu';
import { MenuService } from '../shared/services/menu.service';
import { Utils } from '../shared/utils/utils';
declare let jQuery: any;
declare let swal: any;

@Component({
  selector: 'app-manage-menu',
  templateUrl: './manage-menu.component.html',
  styleUrls: ['./manage-menu.component.css']
})
export class ManageMenuComponent implements OnInit {
  busy: Subscription;
  positionIds: string[] = [];
  positionIdsChild: string[] = [];
  containers: Menu[] = [];
  visibleIndex: number = -1;
  valid: boolean = true;
  childrenPositionIds: string[] = [];
  submitted = false;
  show_after_reload = 0;
  constructor(private menuService: MenuService, private router: Router) {
    if (this.router.url.substr(this.router.url.length - 1) === '1') {
      this.show_after_reload = 1
    }
  }

  ngOnInit(): void {
    this.getAllMenu();
  }

  showSubItem(ind: number) {
    this.containers[ind].menu_children = Utils.sortMenusByIndex(this.containers[ind].menu_children)
    const that = this;
    Utils.initSortableElements('sortable-' + ind, 20);
    jQuery('.sortable').on('sortupdate', function (event, ui) {
      that.childrenPositionIds = jQuery('.sortable-' + ind).sortable('toArray');
      that.containers[ind].menu_children = Utils.sortArrayByPositions(
        that.containers[ind].menu_children,
        that.childrenPositionIds
      );
    });
    if (this.visibleIndex === ind) {
      this.visibleIndex = -1;
    } else {
      this.visibleIndex = ind;
    }
  }

  getAllMenu() {
    this.busy = this.menuService.getAllMenu(this.show_after_reload)
      .subscribe(
        (data: Menu[]) => {
          this.containers = data;
          this.containers = Utils.sortMenusByIndex(this.containers);
          const that = this;
          Utils.initSortableElements('sortable', 20);
          jQuery('.sortable').on('sortupdate', function (event, ui) {
            that.positionIds = jQuery('.sortable').sortable('toArray');
            that.containers = Utils.sortArrayByPositions(
              that.containers,
              that.positionIds
            );
          });
        },
        (error) => {
          swal('Erreur', 'Une erreur est survenue', 'error');
        });
  }

  add() {
    if (this.validateMenu()) {
      const newMenu = new Menu();
      newMenu.index = this.containers[this.containers.length - 1].index + 1;
      newMenu.url = "";
      newMenu.icon = "";
      this.containers.push(newMenu);

    }
    else {
      this.submitted = true;
      swal('Erreur', 'remplir  les champs obligatoires', 'error');
    }
  }

  addSousMenu(i) {
    if (!this.validateSousMenu(i)) {
      swal('Erreur', 'remplir  les champs obligatoires', 'error');
      return;
    }
    const menuChildren = new MenuChildren();
    menuChildren.index = this.containers[i].menu_children.length + 1;
    menuChildren.icon = "";
    this.containers[i].menu_children.push(menuChildren);
  }

  deleteSousMenu(i: number, j: number) {
    this.containers[i].menu_children.splice(j, 1);
  }

  removeItem(i: number) {
    this.containers.splice(i, 1);
  }

  validerMenu() {
    this.submitted = true;
    if (this.validateMenu()) {
      this.busy = this.menuService.addMenu(this.containers, this.show_after_reload).subscribe(data => {
        Utils.sweetAlert('success', 'Succès', 'Opération terminée avec succès');
        this.visibleIndex = -1;
        this.containers = data;
        this.containers = Utils.sortMenusByIndex(this.containers);
      }, error => {
        swal('Erreur', 'Une erreur est survenue', 'error');
      });
    }
    else {
      swal('Erreur', 'remplir  les champs obligatoires', 'error');
    }
  }

  validateMenu() {
    for (let i = 0; i < this.containers.length; i++) {
      if (!this.containers[i].key || !this.validateSousMenu(i)) {
        return false;
      }
    }
    return true;
  }

  validateSousMenu(i) {
    for (let sousMenu of this.containers[i].menu_children) {
      if (!sousMenu.key || !sousMenu.url) {
        return false;
      }
    }
    return true;
  }

}
