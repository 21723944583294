import {StorageService} from './storage.service';
import {Injectable} from '@angular/core';
import {GenericService} from './generic.service';
import {Admin} from '../models/admin/admin';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import { Observable } from 'rxjs';
import {Offre} from '../models/Offre';

@Injectable()
export class AdminService extends GenericService {
    loggedAdmin: Admin;

    constructor(private http: HttpClient, private storageService: StorageService) {
        super();
        this.loggedAdmin = <Admin>storageService.read(StorageService.ADMIN_STORAGE);
    }

    saveMail(object: string, template: string,titre_mail: string,attachments: string[]): Observable<any> {
        return <Observable<any>>this.http.post(environment.baseUrlApi + '/admin/mail/', {
            object: object,
            template: template,
            titre_mail: titre_mail,
            attachments: attachments
        });
    }

    updateMail(mailAdminId: number, object: string, template: string, titre_mail: string,attachments: string[]): Observable<any> {
      return this.http.put(environment.baseUrlApi + '/admin/mail/' + mailAdminId, {
          object: object,
          template: template,
          titre_mail: titre_mail,
          attachments: attachments
      });
  }

    uploadImageMail(image:FormData):Observable<any>{
        const url = environment.baseUrlApi + "/files/uploadresource";
        return this.http.post(url,image);
    }

    getLoggedAdmin() {
      const url = environment.baseUrlApi + "/admin/me";
      return this.http.get(url);
    }
    
    getToken() {
      return <string>this.storageService.read(StorageService.TOKEN_STORAGE);
    }
    getListClients(perPage:any = '',  page:number=1, search:string = "") {
      const url = environment.baseUrlApi + "/admin/all" + "?page=" + page + "&perPage=" + perPage + "&search=" + search;
      return this.http.get(url);
    }

    removeClientByid(adminId: number) {
      const url = environment.baseUrlApi + "/admin/delete/" +adminId;
      return this.http.delete(url);
    }

    addClient(admin: Admin) {
      const url = environment.baseUrlApi + "/admin/add";

      return this.http.post(url, admin);
    }
    getClientById(adminId: number) {
      const url = environment.baseUrlApi + "/admin/" +adminId;

      return this.http.get(url);
    }
    editClient(admin: Admin) {
      const url = environment.baseUrlApi + "/admin/"+ admin.admin_id;
      return this.http.put(url, admin);
    }
    changeClientPaymentStatus(admin_id, offre_id, isPaid) {
        const url = environment.baseUrlApi + '/admin/' + admin_id + '/offre/' + offre_id;
        return this.http.put(url, {isPaid: isPaid});
    }
    getOrganizersPagination(page: number,perPage: number,search: string) { 
      const headers = this.headers.set("Authorization", "Bearer " + this.getToken());
      const url = environment.baseUrlApi + "/super-admin/organizer?perPage=" + perPage + "&page=" + page + "&search=" + search;
      return this.http.get(url,{headers:headers});
    }

    sendCredentialsViaEmailToClient(admin_id) {
      const url = environment.baseUrlApi + "/admin/generate-new-password/"+admin_id;
      return this.http.put(url, {});
    }

    getLatestCongressByAdmin(admin_id)
    {
      const headers = this.headers.set("Authorization", "Bearer " + this.getToken());
      const url = environment.baseUrlApi + "/super-admin/admin/" + admin_id + "/latest-congress";
      return this.http.get(url,{headers:headers});
    }

    createMail(object: string, template: string, mailTypeAdminId: number): Observable<any> {
      const url = `${environment.baseUrlApi}/admin/mail/${mailTypeAdminId}`;
      const payload = {
          object: object,
          template: template
      };
      return this.http.post(url, payload);
  }

 

  
}
