export class Menu {
    menu_id: number = null;
    key: string;
    url: string;
    icon: string;
    reload: boolean;
    index:number;
    menu_children: MenuChildren[] = [];
    showMenuChildren: boolean;
    menu_children_ids: string[] = [];
}

export class MenuChildren {
    menu_children_id: string="";
    key: string;
    url: string;
    icon: string;
    reload: boolean;
    index:number;
    menu_id: number;
}

export class MenuChildrenOffre {
    menu_children_id: string;
    menu_id: number;
    offre_id: number;
    menu: Menu = new Menu();
    menu_children: MenuChildren = new MenuChildren();
}
