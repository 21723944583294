import {Component, OnInit} from '@angular/core';
import {MailTypeAdmin} from "../../shared/models/Mail_Type_Admin";
import {MailService} from "../../shared/services/mail.service";
import {Utils} from "../../shared/utils/utils";
import {Subscription} from "rxjs";
import { Router } from '@angular/router';

declare var swal: any;

@Component({
  selector: 'app-list-mail',
  templateUrl: './list-mail.component.html',
  styleUrls: ['./list-mail.component.css']
})
export class ListMailComponent implements OnInit {

  busy: Subscription;
  mailTypes: MailTypeAdmin[] = [];

  constructor(private mailService: MailService,private router: Router,) {}

  ngOnInit() {
    this.getAllMailTypes();
  }

  getAllMailTypes() {
    this.busy = this.mailService.getAllMailType()
      .subscribe(
        (data: MailTypeAdmin[]) => {
          this.mailTypes = data;
          Utils.initializeDataTables('mail-list-table', 20,4);  
        },
        (error) => {
          swal('Erreur', 'Erreur', 'error');
        }
      )
  }

  addMailType() {
    this.router.navigate(['/manage-mail/type/new']);
  }

}
