import { NgModule } from '@angular/core';
import { ListOffreComponent } from './list-offre/list-offre.component';
import {SharedModule} from '../shared/shared.module';
import {ManageOffreRoutingModule} from './manage-offre-routing.module';
import { AddOffreComponent } from './add-offre/add-offre.component';



@NgModule({
  declarations: [ListOffreComponent, AddOffreComponent],
  imports: [
    SharedModule,
    ManageOffreRoutingModule
  ]
})
export class ManageOffreModule { }
